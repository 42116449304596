<template>
  <transition-toggle-contents>
    <trainedAiDetailTabRAGSettings
      v-if="activeTab === 'RAGGenerativeAI'"
      key="RAGGenerativeAI"
      :datasetListBody="datasetListBody"
      v-bind.sync="currentRAGSettings"
      :response="RAGresponse"
      @test-connection="$emit('test-connection', $event)"
      @test-prompt="$emit('test-prompt', $event)"
      @rag-submit="$emit('rag-submit', $event)"
      @load-dataset-list="$emit('load-dataset-list', $event)"
      @add-new-data="$emit('add-new-data', RAGSettings)"
      @sort-label="$emit('sort-label', $event)"
      @show-dataset="$emit('show-dataset', $event)"
      @icon-menu-click="$emit('icon-menu-click', $event)"
    />
    <trainedAiDetailTabRAGDataset
      v-else-if="activeTab === 'RAGUpload'"
      key="RAGUpload"
      :datasetListBody="datasetListBody"
      v-bind.sync="currentRAGSettings"
      :response="RAGresponse"
      @test-connection="$emit('test-connection', $event)"
      @test-prompt="$emit('test-prompt', $event)"
      @rag-submit="$emit('rag-submit', $event)"
      @load-dataset-list="$emit('load-dataset-list', $event)"
      @add-new-data="$emit('add-new-data', RAGSettings)"
      @sort-label="$emit('sort-label', $event)"
      @show-dataset="$emit('show-dataset', $event)"
      @icon-menu-click="$emit('icon-menu-click', $event)"
    />
    <trainedAiDetailTabRAGPrompt
      v-else-if="activeTab === 'RAGPrompt'"
      key="RAGPrompt"
      :datasetListBody="datasetListBody"
      v-bind.sync="currentRAGSettings"
      :response="RAGresponse"
      @test-connection="$emit('test-connection', $event)"
      @test-prompt="$emit('test-prompt', $event)"
      @rag-submit="$emit('rag-submit', $event)"
      @load-dataset-list="$emit('load-dataset-list', $event)"
      @add-new-data="$emit('add-new-data', RAGSettings)"
      @sort-label="$emit('sort-label', $event)"
      @show-dataset="$emit('show-dataset', $event)"
      @icon-menu-click="$emit('icon-menu-click', $event)"
    />
  </transition-toggle-contents>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import trainedAiDetailTabRAGSettings from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-rag-settings'
import trainedAiDetailTabRAGDataset from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-rag-dataset'
import trainedAiDetailTabRAGPrompt from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-rag-prompt'

export default {
  components: {
    transitionToggleContents,
    trainedAiDetailTabRAGSettings,
    trainedAiDetailTabRAGDataset,
    trainedAiDetailTabRAGPrompt
  },
  data() {
    return {
      currentRAGSettings: JSON.parse(JSON.stringify(this.RAGSettings))
    }
  },
  props: {
    datasetListBody: Object,
    RAGresponse: Object,
    RAGSettings: Object,
    activeTab: String
  },
  computed: {}
}
</script>
