<template>
  <div class="tab-content">
    <loading v-if="datasetListBody.datasetLoading" />
    <dataset-list-main
      v-else
      v-bind="datasetListBody"
      no-datasource
      @add-new-data="$emit('add-new-data')"
      @sort-label="$emit('sort-label', $event)"
      @show-dataset="$emit('show-dataset', $event)"
      @icon-menu-click="$emit('icon-menu-click', $event)"
    />
  </div>
</template>
<script>
import Loading from '@/components/atoms/loading.vue'
import datasetListMain from '@/components/organisms/dataset-list/dataset-list-main.vue'

export default {
  components: {
    datasetListMain,
    Loading
  },
  props: {
    datasetListBody: Object
  },
  data() {
    return {}
  },
  mounted() {
    console.log('load-dataset-list')
    this.$emit('load-dataset-list')
  },
  computed: {
    isTestDisabled() {
      return false
    },
    serviceItems() {
      return [
        {
          id: 0,
          text: 'Azure',
          value: 'Azure'
        }
      ]
    }
  },
  methods: {
    testConnection() {},
    submit() {}
  }
}
</script>

<style lang="scss" scoped>
.tab-content {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  margin: $space-medium;
}

.detail-params-item {
  height: fit-content;
  min-height: adjustVH(48); // input-box-gray の高さ
  margin-bottom: $space-base;
}

.service-select {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.c-func-input {
  &-title {
    margin: 0 0 $space-base;
  }
}
</style>
