<template>
  <div class="trained-ai-feature-importance">
    <div class="trained-ai-feature-importance-size-inner">
      <div
        class="
          trained-ai-detail-title-sub trained-ai-feature-importance-size-title
        "
      >
        <texts
          :text="$t('common.nImportanceShown')"
          color="gray"
          size="small"
        />
        <div class="trained-ai-feature-importance-size-count">
          <input
            v-model.number="scaleSize"
            type="number"
            min="0"
            :max="scaleMax"
          >
        </div>
      </div>
      <div class="trained-ai-feature-importance-size-scale">
        <div class="trained-ai-feature-importance-size-scale-inner">
          <input
            v-model.number="scaleSize"
            class="trained-ai-feature-importance-size-scale-range"
            type="range"
            name="speed"
            min="0"
            :max="scaleMax"
          >
          <div
            class="trained-ai-feature-importance-size-scale-range-status"
            :style="{ '--width': (Number(scaleSize) / scaleMax) * 100 + '%' }"
          />
        </div>
      </div>
    </div>
    <feature-importance
      :predictionColumns="trainedAi.summary.predictionColumns"
      :featureImportance="trainedAi.summary.featureImportance"
      :selectedColumnIndex="selectedColumnIndex"
      :loadFeatureImportance="loadChangeColumn"
      :maxItem="Number(scaleSize)"
      class="trained-ai-detail-multi-importance"
    />
  </div>
</template>

<script>
import FeatureImportance from '@/components/organisms/card-fi.vue'

export default {
  components: {
    FeatureImportance
  },
  data() {
    console.log(this.scaleMax, this.trainedAi?.summary?.featureImportance)
    let scaleMax = 0
    const fi = this.trainedAi?.summary?.featureImportance
    if (fi == null) scaleMax = 0
    scaleMax = Object.keys(fi).length

    return {
      scaleSize: Math.min(scaleMax, 8)
    }
  },
  mounted() {},
  props: {
    trainedAi: Object,
    selectedColumnIndex: Number,
    finishColumns: Array,
    loadChangeColumn: Boolean
  },
  computed: {
    scaleMax() {
      const fi = this.trainedAi?.summary?.featureImportance
      if (fi == null) return 0
      return Object.keys(fi).length
    }
  },
  watch: {
    scaleSize(newVal) {
      if (newVal < 0) {
        this.scaleSize = 0
      } else if (newVal > this.scaleMax) {
        this.scaleSize = this.scaleMax
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-feature-importance {
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(auto, adjustVW(804)) minmax(auto, adjustVW(804));
  grid-column-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;

  &-size {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-medium;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-inner {
      height: fit-content;
      padding: $space-small;
      background: $background;
      border-radius: adjustVW(12);
      box-shadow: $box-shadow-main;
    }
    &-count {
      width: adjustVW(56);
      padding: $space-min;
      border: $border-emphasis;
      font-size: $text-base;
      border-radius: adjustVW(4);
      > input {
        appearance: textfield;
        width: 100%;
        text-align: center;
        &::-webkit-outer-spin-button {
          appearance: none;
          margin: 0;
        }
        &::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }
      }
    }
    &-scale {
      &-inner {
        position: relative;
      }
      &-range {
        appearance: none;
        width: 100%;
        height: adjustVH(8);
        background: $background-sub;
        border-radius: adjustVW(4);
        cursor: pointer;
        outline: none;
        &::-webkit-slider-thumb {
          position: relative;
          appearance: none;
          width: adjustVW(24);
          height: adjustVW(24);
          border: $border-emphasis;
          background: $background;
          border-radius: 9in;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
          z-index: 2;
        }
        &::-moz-range-thumb {
          width: adjustVW(24);
          height: adjustVW(24);
          border: $border-emphasis;
          background: $background;
          border-radius: 9in;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        }
        &-status {
          --width: 50;
          position: absolute;
          top: 50%;
          width: var(--width);
          height: adjustVH(8);
          background: $key-color;
          border-radius: 9in 0 0 9in;
          z-index: 1;
          transform: translateY(-50%);
        }
      }
    }
  }
}
.trained-ai-detail-multi-importance {
  overflow-y: auto;
  height: fit-content;
  max-height: 100%;
}
</style>
