<template>
  <page-top
    class="apply-top-top"
    :title="$t('preprocessing.applyingDataset.title')"
    iconName="backward"
    notScroll
    isBackButton
  >
    <div class="apply-top-function">
      <button-main
        :text="$t('preprocessing.applyAutoPreprocess')"
        type="emphasis"
        :isDisabled="loading || autoPreprocessingApplied"
        @click="$emit('open-auto-preprocess')"
      />
      <button-main
        :text="$t('preprocessing.applyingDataset.finishButton')"
        :type="autoPreprocessingApplied ? 'emphasis' : 'emphasis-sub'"
        :isDisabled="
          loading || preProcLatestVersion == 0 || preProcHeadVersion == 0
        "
        @click="$emit('open-save-dataset', 'saveDataset')"
      />
      <text-box
        v-if="errorPopup.isAutoProcess && errorPopup.isShow"
        isError
        color="caution"
        class="error-box"
      >
        <texts isShowAll color="caution">
          <span v-html="errorPopup.text" />
        </texts>
      </text-box>
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import buttonMain from '@/components/atoms/button-main'
import TextBox from '@/components/atoms/text-box.vue'

export default {
  components: {
    pageTop,
    buttonMain,
    TextBox
  },
  props: {
    tabs: Array,
    activeTab: Number,
    preProcHeadVersion: Number,
    preProcLatestVersion: Number,
    loading: Boolean,
    errorPopup: Object,
    autoPreprocessingApplied: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.apply-top {
  &-function {
    display: flex;
    margin: 0;
  }
  .error-box {
    margin-left: $space-medium;
  }
}
</style>
