<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deleteTrainedAi>
      <trained-ai-detail-popup-delete-trained-ai
        :trainedAi="trainedAi"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @delete="$emit('delete', $event)"
        @check-service="$emit('check-service')"
      />
    </template>
    <template #deleteServiceCheck>
      <trained-ai-detail-popup-delete-service-check
        :trainedAi="trainedAi"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @delete="$emit('delete', $event)"
      />
    </template>
    <template #deleteOptimization>
      <trained-ai-detail-popup-delete-optimization
        :trainedAi="trainedAi"
        :optimizationInfo="optimizationInfo"
        :optimizationResult="optimizationResult"
        :optimizationConditions="optimizationConditions"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @delete-optimization="$emit('delete-optimization', $event)"
      />
    </template>
    <template #modelDownloadError>
      <trained-ai-detail-popup-model-download-error
        @close-modal="$emit('close-modal', $event)"
      />
    </template>
    <template #addNewData>
      <popup-add-dataset
        v-bind="datasetListBody.addDataset"
        :accountInfo="accountInfo"
        :addNewData="addDataset"
        :fileInputValue="addDataset.fileInputValue"
        :uploadLoading="datasetListBody.addDataset.uploadLoading"
        :disableClick="disableClick"
        noDatasource
        @on-file-input="$emit('on-file-input', $event)"
        @close-modal="$emit('close-modal', $event)"
        @text-input="$emit($event)"
        @datasource-input="$emit('datasource-input', $event)"
        @show-table="$emit('show-table', $event)"
        @start-uploading="$emit('start-uploading', $event)"
        @finished-uploading="$emit('finished-uploading')"
        @import-dataset-loading="$emit('import-dataset-loading', $event)"
        @datasource-items="$emit('datasource-items', $event)"
        @do-import="$emit('do-import', $event)"
        @file-clear="$emit('file-clear')"
        @test-datasource="$emit('test-datasource', $event)"
        @new-datasource="$emit('new-datasource', $event)"
        @input-create-form="$emit('input-create-form', $event)"
        @get-and-set-sql-list="$emit('get-and-set-sql-list', $event)"
        @get-sql-list="$emit('get-sql-list', $event)"
        @show-sql-edit="$emit('show-sql-edit', $event)"
        @select-sql-preview-table="$emit('select-sql-preview-table', $event)"
        @set-datasource="$emit('set-datasource', $event)"
      />
      <div v-if="datasetListBody.addDataset.uploadLoading">
        <wait-setting-dataset
          :progressSettingDataset="datasetListBody.addDataset.progress"
          :progressSettingDatasetMax="datasetListBody.addDataset.maxProgress"
          :progressStatus="datasetListBody.addDataset.progressStatus"
          :uploadLearningDataWarnings="
            datasetListBody.addDataset.uploadLearningDataWarnings
          "
          :isLargeCsv="datasetListBody.addDataset.isLargeCsv"
        />
      </div>
    </template>
  </popup-array>
</template>

<script>
import trainedAiDetailPopupDeleteTrainedAi from './trained-ai-detail-popup-delete-trained-ai'
import trainedAiDetailPopupDeleteServiceCheck from './trained-ai-detail-popup-delete-service-check'
import trainedAiDetailPopupDeleteOptimization from './trained-ai-detail-popup-delete-optimization'
import trainedAiDetailPopupModelDownloadError from './popup/model-download-error'
// for rag dataset
import popupAddDataset from '@/components/organisms/dataset-list/popup/add-dataset'
// import popupDeleteData from './popup/delete-data.vue'
import waitSettingDataset from '@/components/organisms/wait-setting-dataset'
import { popupArray } from '@/components/molecules/popup'

export default {
  components: {
    trainedAiDetailPopupDeleteTrainedAi,
    trainedAiDetailPopupDeleteServiceCheck,
    trainedAiDetailPopupDeleteOptimization,
    trainedAiDetailPopupModelDownloadError,
    popupAddDataset,
    //    popupDeleteData,
    waitSettingDataset,
    popupArray
  },
  computed: {
    popupDefs() {
      return {
        deleteTrainedAi: {
          title: this.$t('trainedAiDetails.popup.deleteConfirm')
        },
        deleteServiceCheck: {
          title: this.$t('trainedAiDetails.popup.serviceDeleteCheck.title')
        },
        deleteOptimization: {
          title: this.$t('trainedAi.optimization.delete.title')
        },
        modelDownloadError: {
          title: this.$t('alerts.modelDownloadError.title')
        },
        addNewData: {
          title: this.$t('datasetList.popup.addDataset.title'),
          maxHeight: true
        }
      }
    }
  },
  props: {
    showPopup: Array,
    trainedAi: Object,
    disableClick: Boolean,
    optimizationInfo: Object,
    optimizationResult: Object,
    optimizationConditions: Array,

    accountInfo: Object,
    datasetListBody: Object,
    addDataset: Object,
    RAGSettingsForUpload: Object
  }
}
</script>
