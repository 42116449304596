export function createInners(names) {
  const res = {}
  names.forEach((name) => {
    res['_' + name] = {
      get() {
        return this.$props[name]
      },
      set(value) {
        this.$emit('update:' + name, value)
      }
    }
  })
  return res
}
