<template>
  <div class="side-wrap">
    <div class="side-dataset">
      <div class="side-dataset-top">
        <texts
          class="side-title"
          :text="$t('preprocessing.sideTopTitle')"
          isBold
        />
        <div class="side-box">
          <select-box
            :items="datasetArray"
            :firstSelectItem="firstSelectDataset"
            isGray
            min
            scrollBar
            @select-item="
              $emit('select-dataset', datasetArray[$event.selectItem].id)
            "
          />
        </div>
      </div>
      <div class="side-dataset-bottom">
        <div class="side-dataset-bottom-num">
          <text-with-title
            :title="$t('preprocessing.applyingDataset.datasetNum')"
            :text="nData"
          />
        </div>
        <div v-if="firstSelectDataset" class="side-dataset-bottom-detail">
          <button @click="$emit('go-detail')">
            <text-with-icon
              :text="$t('common.openDetail')"
              iconName="newTab"
              pos="right"
              color="link-default"
              size="small"
            />
          </button>
        </div>
      </div>
    </div>
    <div v-if="preprocessingArray.length > 0" class="side-preprocessing">
      <div class="side-preprocessing-top">
        <texts
          class="side-title"
          :text="$t('preprocessing.sideBottomTitle')"
          isBold
        />
        <div class="side-box">
          <select-box
            :items="preprocessingArray"
            :placeholder="
              existsPreprocessing
                ? ''
                : $t('preprocessing.applyingDataset.placeholderPreprocessing')
            "
            :firstSelectItem="firstSelectPreprocessing"
            isGray
            min
            scrollBar
            @select-item="
              $emit(
                'select-preprocessing',
                preprocessingArray[$event.selectItem].id
              )
            "
          />
        </div>
        <div v-if="existsPreprocessing" class="side-chart-wrap">
          <texts
            class="side-title side-title-min"
            :text="$t('preprocessing.preprocessingContent')"
            color="gray"
            size="small"
          />
          <div class="side-chart">
            <preprocessing-chart-list-min
              class="side-chart-list"
              :layers="preprocessingList[preprocessingId].layers"
              :isUsed="isUsed"
              :line="2"
            />
            <div v-if="isUsed" class="side-text">
              <texts
                size="small"
                color="emphasis"
                isBold
                :text="$t('preprocessing.applied')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="side-preprocessing-bottom">
        <popup-balloon
          class="side-popup"
          :isShow="!errorPopup.isAutoProcess && errorPopup.isShow"
          showLeft
          error
          @close="$emit('close-error-popup')"
        >
          <texts class="side-popup-inner" isShowAll>
            <span v-html="errorPopup.text" />
          </texts>
        </popup-balloon>
        <div class="side-button">
          <button-main
            :text="$t('preprocessing.applyingDataset.applyButton')"
            :type="isUsed || !existsPreprocessing ? 'disabled' : 'emphasis'"
            :isDisabled="isUsed || !existsPreprocessing"
            @click="$emit('apply-preprocessing')"
          />
        </div>
      </div>
    </div>
    <div v-else />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon'
import selectBox from '@/components/molecules/select-box'
import preprocessingChartListMin from '@/components/molecules/preprocessing-chart-list-min'
import buttonMain from '@/components/atoms/button-main'
import popupBalloon from '@/components/atoms/popup-balloon'

export default {
  components: {
    texts,
    textWithTitle,
    textWithIcon,
    selectBox,
    preprocessingChartListMin,
    buttonMain,
    popupBalloon
  },
  props: {
    preprocessingList: Object,
    datasetList: Object,
    preprocessingId: [String, null],
    datasetId: String,
    errorPopup: Object,
    isUsed: Boolean,
    nRows: Number
  },
  computed: {
    preprocessingArray() {
      const preprocessingArray = Object.values(this.preprocessingList)
      // 最終更新日の降順
      preprocessingArray.sort((x, y) => (x.updateTime > y.updateTime ? -1 : 1))
      return preprocessingArray
    },
    datasetArray() {
      const datasetArray = Object.values(this.datasetList)
      // 最終更新日の降順
      datasetArray.sort((x, y) => (x.updateTime > y.updateTime ? -1 : 1))
      return datasetArray
    },
    firstSelectPreprocessing() {
      if (this.existsPreprocessing) {
        return (
          this.preprocessingArray.find((x) => x.id === this.preprocessingId) ??
          {}
        )
      } else {
        return {}
      }
    },
    existsPreprocessing() {
      return (
        this.preprocessingId !== null &&
        this.preprocessingId in this.preprocessingList
      )
    },
    firstSelectDataset() {
      if (
        this.datasetId === null ||
        !this.datasetList ||
        this.datasetList.length === 0
      ) {
        return {}
      } else {
        return this.datasetList[this.datasetId]
      }
    },
    nData() {
      return this.datasetList[this.datasetId]?.nData
    },
    layers() {
      return this.firstSelectPreprocessing?.layers
    }
  }
}
</script>

<style lang="scss" scoped>
.side {
  &-wrap {
    display: grid;
    grid-template-areas:
      'top'
      'bottom';
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    grid-row-gap: $space-medium;
    height: 100%;
  }
  &-dataset {
    grid-area: top;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: $space-sub $space-base;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-top {
      flex-shrink: 1;
      height: 100%;
    }
    &-bottom {
      display: flex;
      flex-shrink: 0;
      align-items: flex-end;
      justify-content: space-between;
      margin: 0 $space-base;
      &-detail {
        display: flex;
        justify-content: space-between;
        margin: 0;
      }
    }
  }
  &-preprocessing {
    grid-area: bottom;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $space-sub $space-base;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-top {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &-bottom {
      position: relative;
      margin: 0 $space-base;
    }
  }
  &-box {
    flex-shrink: 0;
    height: adjustVH(48); // Zepplinと違いますがちょっと狭かったので微調整
    margin: 0 $space-base $space-small;
  }
  &-chart {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    margin: 0 $space-base $space-small;
    &-list {
      overflow-y: scroll;
      flex-shrink: 1;
      flex-grow: 1;
      padding: 0 $space-sub 0 0;
      @include scrollbar;
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      flex-grow: 1;
    }
  }
  &-button {
    margin: auto 0 0 auto;
  }
  &-title {
    flex-shrink: 0;
    margin: 0 $space-base $space-base;
    &-min {
      margin-bottom: $space-text;
    }
  }
  &-text {
    margin-top: $space-small;
  }
}
</style>

<style lang="scss">
.preprocessing-caution {
  // i18nで生成されるHTMLにはScope効かないので別にしています
  color: $text-caution;
}
</style>
