<template>
  <div class="popup-wrap">
    <div class="popup-head" :style="{ '--tabs': tabs.length }">
      <tab-list
        :activeTabIndex="activeTabIndex"
        :tabs="tabs"
        @change-tab="changeTab"
      />
    </div>
    <components
      :is="activeTab"
      :datasetListBody="datasetListBody"
      v-bind.sync="newData"
      :response="RAGresponse"
      class="popup-body"
      @test-connection="$emit('test-connection', $event)"
      @test-prompt="$emit('test-prompt', $event)"
      @rag-submit="$emit('rag-submit', $event)"
      @load-dataset-list="$emit('load-dataset-list', $event)"
      @add-new-data="$emit('add-new-data', newData)"
      @sort-label="$emit('sort-label', $event)"
      @show-dataset="$emit('show-dataset', $event)"
      @icon-menu-click="$emit('icon-menu-click', $event)"
    />
  </div>
</template>

<script>
import tabList from '@/components/molecules/tab-list'
import tabGenerativeAI from './tabs/generative_ai'
import tabRAG from './tabs/rag'
import tabPrompt from './tabs/prompt'

export default {
  components: {
    tabList,
    tabGenerativeAI,
    tabRAG,
    tabPrompt
  },
  props: {
    datasetListBody: Object,
    RAGresponse: Object
  },
  data() {
    return {
      activeTab: 'tabGenerativeAI',
      newData: {
        serviceType: 'Azure',
        deploymentName: '',
        apiKey: '',
        endpoint: '',
        apiVersion: '',
        temperture: 0,
        topP: 1,
        maxTokens: 4096,
        presencePenalty: 0.0,
        frequencyPenalty: 0.0,
        stop: '',

        prompt: `以下のコンテキストに基づいて、ユーザーの質問に対する返答の候補を3つ提案してください。
    各返答は明確で簡潔にし、それぞれに確信度を0から1で付けてください。
    コンテキストから読み取れない場合は「分かりません」と答えてください。
    同じ内容の回答は避け、バラエティを持たせてください。

    コンテキスト: {rag_result}

    質問内容: {question}

    返答の形式はJSON形式にしてください。他の言葉は不要です。以下のフォーマットを使用してください。間違っても\`\`\`jsonなどはつけないでください。
    {{
        "answers": [
            {{"answer": "回答1", "確信度": 0~1}},
            {{"answer": "回答2", "確信度": 0~1}},
            {{"answer": "回答3", "確信度": 0~1}},
        ]
    }}

    回答:{{`,
        question: 'プランCの料金はいくらですか？',
        ragResult: `質問：プランAは個人向けですか？ 回答：法人向けです。
質問：プランAの料金はいくらですか？ 回答：月30万円です。
質問：プランBとプランAはどちらが安いですか. 回答 プランBの方がプランAより10万円安いです。
質問：プランCとプランAはどちらが安いですか. 回答 プランCの方がプランAより5万円安いです。
質問：プランCは個人向けですか？ 回答：個人向けです。
質問：プランCとプランBの違いはなんですか？ 回答：回答なし。`
      }
    }
  },
  computed: {
    tabs() {
      return [
        {
          id: 0,
          name: this.$t('RAGSettings.tabs.generativeAI.title'),
          iconName: 'dataset',
          value: 'tabGenerativeAI'
        },
        {
          id: 1,
          name: this.$t('RAGSettings.tabs.rag.title'),
          iconName: 'detail',
          value: 'tabRAG'
        },
        {
          id: 2,
          name: this.$t('RAGSettings.tabs.prompt.title'),
          iconName: 'datasource',
          value: 'tabPrompt'
        }
      ]
    }
  },
  methods: {
    changeTab(e) {
      this.activeTab = e
      this.activeTabIndex = this.tabs.find((tab) => {
        return tab.value === e
      }).id
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  &-wrap {
    display: grid;
    grid-template-rows: min-content auto;
    width: 100%;
    height: 100%;
  }
  &-head {
    grid-row: 1;
    height: fit-content;
  }
  &-body {
    grid-row: 2;
  }
}
</style>
