<template>
  <div class="tab-content">
    <div class="detail-params-item service-select">
      <div class="c-func-input-title">
        <texts size="small" color="gray">
          {{ $t('RAGSettings.tabs.generativeAI.params.serviceType') }}
        </texts>
      </div>
      <select-box v-model="_serviceType" :items="serviceItems" isGray />
    </div>
    <input-box
      v-model="_deploymentName"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.deploymentName')"
      isGray
    />
    <input-box-validation
      v-model="_apiKey"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.apiKey')"
      isPass
    />
    <input-box-validation
      v-model="_endpoint"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.endpoint')"
    />
    <input-box-validation
      v-model="_apiVersion"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.apiVersion')"
    />
    <div class="test-button">
      <button-main
        :text="$t('RAGSettings.tabs.generativeAI.testButton')"
        :type="isTestDisabled ? 'disabled' : 'sub'"
        :isDisabled="isTestDisabled"
        @click="testConnection"
      />
      <texts
        v-if="response.test.ok === true"
        color="emphasis"
        class="result-text"
      >
        接続テストに成功しました
      </texts>
      <texts
        v-if="response.test.ok === false"
        color="caution"
        class="result-text"
      >
        接続テストに失敗しました
      </texts>
    </div>
    <input-box
      v-model="_temperture"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.temperture')"
      :min="0"
      :max="1"
      :step="0.001"
      number
      isGray
    />
    <input-box
      v-model="_topP"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.topP')"
      :min="0"
      :max="1"
      :step="0.001"
      number
      isGray
    />
    <input-box
      v-model="_maxTokens"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.maxTokens')"
      :min="100"
      :max="128000"
      :step="1"
      number
      isGray
    />
    <input-box
      v-model="_presencePenalty"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.presencePenalty')"
      :min="-2"
      :max="2"
      :step="0.001"
      number
      isGray
    />
    <input-box
      v-model="_frequencyPenalty"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.frequencyPenalty')"
      :min="-2"
      :max="2"
      :step="0.001"
      number
      isGray
    />
    <input-box
      v-model="_stop"
      class="detail-params-item"
      :title="$t('RAGSettings.tabs.generativeAI.params.stop')"
      isGray
    />
  </div>
</template>
<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import inputBox from '@/components/molecules/input-box'
import SelectBox from '@/components/molecules/select-box.vue'

function createInners(names) {
  const res = {}
  names.forEach((name) => {
    res['_' + name] = {
      get() {
        return this.$props[name]
      },
      set(value) {
        this.$emit('update:' + name, value)
      }
    }
  })
  return res
}

export default {
  components: {
    texts,
    buttonMain,
    inputBoxValidation,
    inputBox,
    SelectBox
  },
  props: {
    serviceType: String,
    deploymentName: String,
    apiKey: String,
    endpoint: String,
    apiVersion: String,
    temperture: Number,
    topP: Number,
    maxTokens: Number,
    presencePenalty: Number,
    frequencyPenalty: Number,
    stop: String,

    response: Object
  },
  computed: {
    ...createInners([
      'serviceType',
      'deploymentName',
      'apiKey',
      'endpoint',
      'apiVersion',
      'temperture',
      'topP',
      'maxTokens',
      'presencePenalty',
      'frequencyPenalty',
      'stop'
    ]),
    isTestDisabled() {
      return false
    },
    serviceItems() {
      return [
        {
          id: 0,
          text: 'Azure',
          value: 'Azure'
        }
      ]
    }
  },
  methods: {
    testConnection() {
      this.$emit('test-connection', {
        serviceType: this.serviceType,
        deploymentName: this.deploymentName,
        apiKey: this.apiKey,
        endpoint: this.endpoint,
        apiVersion: this.apiVersion
      })
    },
    submit() {}
  }
}
</script>

<style lang="scss" scoped>
.tab-content {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  margin: $space-medium;
}

.detail-params-item {
  height: fit-content;
  min-height: adjustVH(48); // input-box-gray の高さ
  margin-bottom: $space-base;
}

.service-select {
  display: flex;
  flex-direction: column;
  height: min-content;
}

.c-func-input {
  &-title {
    margin: 0 0 $space-base;
  }
}

.test-button {
  display: flex;
  align-items: center;

  .result-text {
    margin: 0 2rem;
  }
}
</style>
