<template>
  <div class="trained-ai-detail-wrap">
    <trained-ai-detail-top
      class="trained-ai-detail-top"
      :accountInfo="accountInfo"
      :description="description"
      :title="title"
      :trainedAi="trainedAi"
      :trainedAIFormValidate="editInfo.trainedAIFormValidate"
      :editLoading="editInfo.editLoading"
      @change-description="$emit('change-description', $event)"
      @input-edit-form="$emit('input-edit-form', $event)"
    />
    <trained-ai-detail-body
      :accountInfo="accountInfo"
      :trainedAi="trainedAi"
      :featureImportanceScatter="featureImportanceScatter"
      :loadChangeColumn="loadChangeColumn"
      :selectedColumnIndex="pageBody.selectedColumnIndex"
      :optimizationInfo="pageBody.optimizationInfo"
      :optimizationResult="pageBody.optimizationResult"
      :optimizationConditions="pageBody.optimizationConditions"
      :checkOptimization="checkOptimization"
      :isNotAvailableCustomblock="pageBody.isNotAvailableCustomblock"
      :threshold="threshold"
      :reversePositive="reversePositive"
      :textMining="pageBody.textMining"
      :activeTabProps="pageBody.activeTab"
      :testDatasetInfo="testDatasetInfo"
      :clusteringSetting="pageBody.clusteringSetting"
      :clusteringResult="pageBody.clusteringResult"
      :clusteringDistributions="pageBody.clusteringDistributions"
      :regressionGraph="pageBody.regressionGraph"
      :inferenceListInfo="pageBody.inferenceListInfo"
      :RAGresponse="RAGresponse"
      :RAGSettings="RAGSettings"
      :datasetListBody="datasetListBody"
      @change-tab="$emit('change-tab', $event)"
      @menu-click="$emit('menu-click', $event)"
      @load-feature-importance="$emit('load-feature-importance')"
      @change-column="$emit('change-column', $event)"
      @input-edit-form="$emit('input-edit-form', $event)"
      @save-edit-optimization-form="
        $emit('save-edit-optimization-form', $event)
      "
      @show-detail-optimization="$emit('show-detail-optimization', $event)"
      @show-delete-optimization="$emit('show-delete-optimization')"
      @change-page="$emit('change-page', $event)"
      @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
      @change-filter-value="$emit('change-filter-value', $event)"
      @download-result="$emit('download-result', $event)"
      @download-test-dataset="$emit('download-test-dataset', $event)"
      @change-threshold="$emit('change-threshold', $event)"
      @reverse-positive="$emit('reverse-positive', $event)"
      @load-text-mining="$emit('load-text-mining', $event)"
      @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
      @input-clustering-setting="$emit('input-clustering-setting', $event)"
      @download-clustering-result="$emit('download-clustering-result', $event)"
      @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
      @change-clustering-distribution-column="
        $emit('change-clustering-distribution-column', $event)
      "
      @toggle-clustering-show-dimension="
        $emit('toggle-clustering-show-dimension', $event)
      "
      @select-regression-graph="$emit('select-regression-graph', $event)"
      @load-inference-info="$emit('load-inference-info', $event)"
      @show-inference="$emit('show-inference', $event)"
      @test-connection="$emit('test-connection', $event)"
      @test-prompt="$emit('test-prompt', $event)"
      @rag-submit="$emit('rag-submit', $event)"
      @load-dataset-list="$emit('load-dataset-list', $event)"
      @add-new-data="$emit('add-new-data', $event)"
      @sort-label="$emit('sort-label', $event)"
      @show-dataset="$emit('show-dataset', $event)"
      @icon-menu-click="$emit('icon-menu-click', $event)"
    />
  </div>
</template>

<script>
import trainedAiDetailTop from './trained-ai-detail-top'
import trainedAiDetailBody from './trained-ai-detail-body'

export default {
  components: {
    trainedAiDetailTop,
    trainedAiDetailBody
  },
  props: {
    accountInfo: Object,
    editInfo: Object,
    featureImportanceScatter: Object,
    pageTop: Object,
    pageBody: Object,
    tabItem: Object,
    trainedAi: Object,
    loadChangeColumn: Boolean,
    checkOptimization: Boolean,
    threshold: Number,
    reversePositive: Boolean,
    testDatasetInfo: Object,

    RAGresponse: Object,
    RAGSettings: Object,
    datasetListBody: Object
  },
  computed: {
    title() {
      return this.trainedAi?.summary?.name
    },
    description() {
      return this.trainedAi?.summary?.description
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-detail {
  &-wrap {
    display: grid;
    grid-template-rows: minmax(auto, adjustVH(192)) minmax(auto, 1fr);
    grid-row-gap: $space-small;
    height: 100vh - adjustVH($headerTabHeight);
    padding: 0;
  }
  &-top {
    padding: 0 $space-medium;
  }
}
</style>
