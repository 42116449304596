<template>
  <div class="tab-content">
    <div class="prompt-area">
      <input-box
        v-model="_prompt"
        isGray
        isTextArea
        class="prompt-textarea"
        :title="$t('RAGSettings.prompt')"
      />
      <div>
        {{ $t('RAGSettings.promptWarning') }}
      </div>
    </div>
    <div class="right-area">
      <button-main
        :text="$t('RAGSettings.tabs.generativeAI.saveButton')"
        :type="isTestDisabled ? 'disabled' : 'sub'"
        :isDisabled="isTestDisabled"
        @click="submit"
      />
      <input-box
        v-model="_question"
        class="prompt-textarea"
        isGray
        isTextArea
        :title="$t('RAGSettings.question')"
      />
      <input-box
        v-model="_ragResult"
        class="rag-result-textarea"
        isGray
        isTextArea
        :title="$t('RAGSettings.ragResult')"
      />
      <button-main
        :text="$t('RAGSettings.tabs.generativeAI.testSubmitButton')"
        :type="isTestDisabled ? 'disabled' : 'sub'"
        :isDisabled="isTestDisabled"
        @click="testPrompt"
      />
      <input-box
        :value="responseText"
        class="response-textarea"
        isGray
        isTextArea
        :title="$t('RAGSettings.response')"
      />
    </div>
  </div>
</template>
<script>
import buttonMain from '@/components/atoms/button-main'
import inputBox from '@/components/molecules/input-box'
import { createInners } from '@/lib/createInner'

export default {
  components: {
    buttonMain,
    inputBox
  },
  props: {
    prompt: String,
    question: String,
    ragResult: String,
    response: Object,

    serviceType: String,
    deploymentName: String,
    apiKey: String,
    endpoint: String,
    apiVersion: String,

    temperture: Number,
    topP: Number,
    maxTokens: Number,
    presencePenalty: Number,
    frequencyPenalty: Number,
    stop: String
  },
  computed: {
    ...createInners(['prompt', 'question', 'ragResult']),
    isTestDisabled() {
      return false
    },
    serviceItems() {
      return [
        {
          id: 0,
          text: 'Azure',
          value: 'Azure'
        }
      ]
    },
    responseText() {
      return this.response.promptTest?.result?.answers
        ?.map((x) => `${x.answer} ${x['確信度']}`)
        .join('\n')
    }
  },
  methods: {
    testPrompt() {
      this.$emit('test-prompt', {
        serviceType: this.serviceType,
        deploymentName: this.deploymentName,
        apiKey: this.apiKey,
        endpoint: this.endpoint,
        apiVersion: this.apiVersion,

        temperture: this.temperture,
        topP: this.topP,
        maxTokens: this.maxTokens,
        presencePenalty: this.presencePenalty,
        frequencyPenalty: this.frequencyPenalty,
        stop: this.stop,

        prompt: this.prompt,
        question: this.question,
        ragResult: this.ragResult
      })
    },
    submit() {
      this.$emit('rag-submit', {
        serviceType: this.serviceType,
        deploymentName: this.deploymentName,
        apiKey: this.apiKey,
        endpoint: this.endpoint,
        apiVersion: this.apiVersion,

        temperture: this.temperture,
        topP: this.topP,
        maxTokens: this.maxTokens,
        presencePenalty: this.presencePenalty,
        frequencyPenalty: this.frequencyPenalty,
        stop: this.stop,

        prompt: this.prompt,
        question: this.question,
        ragResult: this.ragResult
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-content {
  overflow-y: scroll;

  display: grid;
  grid-template-areas: 'left right';
  width: 100%;
  height: 100%;
  margin: $space-medium;

  .prompt-area {
    grid-area: left;

    .prompt-textarea {
      height: 90%;
    }
  }

  .right-area {
    grid-area: right;

    .prompt-textarea {
      height: 30%;
    }

    .rag-result-textarea {
      height: 30%;
    }

    .response-textarea {
      height: 20%;
    }
  }
}
</style>
