export function inferenceTypeOptions(trainedAI, checkNaturalLanguages) {
  if (trainedAI.type === 'TIME') {
    return [{ value: 'time', name: this.$t('inference.type.timeseries') }]
  } else if (trainedAI.type === 'TIME_TRANSFORMER') {
    return [
      {
        value: 'time_transformer',
        name: this.$t('inference.type.timeTransformer')
      }
    ]
  } else if (trainedAI.type === 'TIME_TRANSFORMER2') {
    return [
      {
        value: 'time_transformerV2',
        name: this.$t('inference.type.timeTransformer')
      }
    ]
  } else if (trainedAI.type === 'DEEP') {
    return [
      {
        value: 'classification',
        name: this.$t('inference.type.classification')
      },
      { value: 'vectorization', name: this.$t('inference.type.vectorization') },
      { value: 'dimRed', name: this.$t('inference.type.dimRed') }
    ]
  } else if (trainedAI.type === 'FINETUNING_DEEP') {
    return [
      {
        value: 'classification',
        name: this.$t('inference.type.classification')
      }
    ]
  } else if (trainedAI.type === 'REGRESSION') {
    return [{ value: 'regression', name: this.$t('inference.type.regression') }]
  } else if (trainedAI.type === 'CLASSIFICATION') {
    if (checkNaturalLanguages) {
      return [
        {
          value: 'classification',
          name: this.$t('inference.type.classification')
        },
        {
          value: 'vectorization',
          name: this.$t('inference.type.vectorization')
        }
      ]
    }
    return [
      {
        value: 'classification',
        name: this.$t('inference.type.classification')
      }
    ]
  } else if (trainedAI.type === 'NO_ACCURACY') {
    return [
      {
        value: 'textMining',
        name: this.$t('trainedAiDetails.tabs.wordCloud')
      }
    ]
  } else if (trainedAI.type === 'CLUSTERFLOW') {
    return [
      {
        value: 'clustering',
        name: this.$t('inference.type.clustering')
      }
    ]
  } else if (trainedAI.type === 'RAG') {
    return [
      {
        value: 'rag',
        name: this.$t('inference.type.rag')
      }
    ]
  }
  console.log(trainedAI.type)
  return [{ value: 'classification', name: this.$t('inference.type.classification') }, { value: 'regression', name: this.$t('inference.type.regression') }, { value: 'vectorization', name: this.$t('inference.type.vectorization') }, { value: 'dimRed', name: this.$t('inference.type.dimRed') }, { value: 'time', name: this.$t('inference.type.timeseries') }]
}

export function isNatualLanguage(trainedAiDetail) {
  if (!trainedAiDetail?.layer_info) return false
  const checkTarget = trainedAiDetail.layer_info.find(
    (layer) => layer.name === 'inputData'
  )
  if (!checkTarget) return false
  return checkTarget?.params.dataType.value === 'texts'
}

export function usedCustomblockList(layerInfo) {
  if (!layerInfo) return []
  const checkTarget = layerInfo.filter((layer) => layer.name === 'customblock')
  return checkTarget
}

export function preprocessAvailable(trainedAi, trainedAiDetail) {
  if (isNatualLanguage(trainedAiDetail)) {
    return false
  }
  const type = trainedAi.inferenceType ?? trainedAi.type
  return ['CLASSIFICATION', 'REGRESSION', 'OPTIMIZATION'].indexOf(type) >= 0
}
export function datasetAvailable(trainedAi) {
  return trainedAi?.inferenceType !== 'TIME'
}

export function datasetSelectAvailable(trainedAi) {
  return (
    trainedAi?.inferenceType !== 'TIME' &&
    trainedAi?.inferenceType !== 'DEEP' &&
    trainedAi?.inferenceType !== 'FINETUNING_DEEP'
  )
}
