<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="preventLeave"
      :title="
        trainingProgressStatus === 'finishTraining'
          ? $t('training.popup.titles.preventLeave')
          : $t('training.popup.titles.preventLeaveStop')
      "
      :saveButton="
        trainingProgressStatus === 'finishTraining'
          ? $t('training.popup.save.saveAiButton')
          : $t('training.popup.save.keepTraining')
      "
      :leaveButton="
        trainingProgressStatus === 'finishTraining'
          ? $t('training.popup.save.notSaveAiButton')
          : $t('training.popup.save.stopTrain')
      "
      @save-event="
        trainingProgressStatus === 'finishTraining'
          ? openSaveTrainedAi()
          : closePreventLeavePopup()
      "
    >
      <texts
        :text="
          trainingProgressStatus === 'finishTraining'
            ? $t('training.popup.prevent.text1')
            : $t('training.popup.prevent.text1A')
        "
      />
      <texts
        v-show="trainingProgressStatus === 'finishTraining'"
        :text="$t('training.popup.prevent.text2')"
      />
    </prevent-leave>
    <training
      v-bind="data"
      :headerTabs="headerTabs"
      :sidebar="sidebar"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      :rawPredictionColumnOptions="rawPredictionColumnOptions"
      :selectedTrainingData="trainingDataset"
      :selectedTrainingDataSample="trainingDatasetSample.labels"
      :selectedRecipe="recipeDetail"
      :recipeType="recipeType"
      :accountInfo="accountInfo"
      :numOwntrainedAIs="numOwntrainedAIs"
      :numCreatingtrainedAIs="numCreatingtrainedAIs"
      :charts="charts"
      :chartsData="chartsData"
      :trainingProgressStatus="trainingProgressStatus"
      :runningJobs="runningJobs"
      :elapsedTime="elapsedTime"
      :trainingNumIter="trainingNumIter"
      :trainingProgress="trainingProgress"
      :optimizationNumIter="optimizationNumIter"
      :optimizationProgress="optimizationProgress"
      :popup="popup"
      :sortedTrainedAis="sortedTrainedAis"
      :mainData="mainData"
      :loading="loading"
      :loadingSave="loadingSave"
      :loadingDelete="loadingDelete"
      :loadingReGet="loadingReGet"
      :message="message"
      :errorManual="errorManual"
      :errorDetail="errorDetail"
      :isSaved="isSaved"
      :isStopped="isStopped"
      :sortedFlag="sortedFlag"
      :bestParams="bestParams ? bestParams : null"
      :trials="trials"
      :validateInfo="validateInfo"
      :disableClick="disableClick"
      :incorrectOrder="incorrectOrder"
      :finishColumns="finishColumns"
      :loadChangeColumn="loadChangeColumn"
      :checkOptimization="checkOptimization"
      :learningPredictionColumns="learningPredictionColumns"
      :threshold="threshold"
      :reversePositive="reversePositive"
      :testDatasetInfo="testDatasetInfo"
      :showTrainedAiType="showTrainedAiType"
      :showTrainedAiIndex="showTrainedAiIndex"
      :addDataset="mixin_datasetList.addDataset"
      :RAGresponse="RAGresponse"
      :datasetListBody="datasetListBody"
      @close-modal="closePopup($event)"
      @delete-confirm="deleteConfirm()"
      @delete-trained-ai="showPopup('deleteTrainedAi')"
      @input-create-form="inputCreateForm($event)"
      @move-inference="moveInference()"
      @re-train="reTrain()"
      @save-confirm="saveConfirm($event)"
      @discard-confirm="showPopup('deleteTrainedAi')"
      @save-trained-ai="openSaveTrainedAi()"
      @save-this-trained-ai="saveThisTrainedAI()"
      @show-graph="showGraph($event)"
      @training-start="trainingStartButton($event)"
      @training-start-over="trainingStartOver()"
      @training-stop="showPopup('trainingStop')"
      @background-training-stop="backgroundTrainingStopPopup"
      @training-stop-confirm="trainingStopConfirm()"
      @background-training-stop-confirm="backgroundTrainingStopConfirm"
      @not-prevent="notPrevent"
      @page-back="pageBack"
      @change-column="changeColumn"
      @check-expressions="checkExpressions"
      @save-edit-optimization-form="saveEditOptimizationForm"
      @change-page="changePageOptimization"
      @change-test-dataset-page="changePageTestDataset"
      @change-filter-value="changeFilterValue"
      @change-tab-optimization="changeTabOptimization"
      @show-detail-optimization="changeTabOptimization"
      @download-result="downloadResult"
      @download-test-dataset="downloadTestDataset"
      @move-this-project="moveThisProject"
      @move-optimization="moveOptimization"
      @close-optimization-tutorial="closeOptimizationTutorial"
      @after-enter-page="afterEnterPage"
      @show-optimization-tutorial="showOptimizationTutorial"
      @close-optimization-result-tutorial="closeOptimizationResultTutorial"
      @change-threshold="changeThreshold"
      @reverse-positive="changePositive"
      @load-text-mining="loadTextMining"
      @change-tab-text-mining="changeTabTextMining"
      @update-show-type="updateShowType"
      @update-show-trained-ai="updateShowTrainedAi"
      @show-time-transformer-before-setting="showTimeTransformerV2BeforeSetting"
      @select-time-transformer-setting="selectTimeTransformerV2Setting"
      @input-clustering-setting="inputClusteringSetting"
      @download-clustering-result="downloadClustering"
      @change-clustering-distribution-column="
        changeClusteringDistributionColumn
      "
      @toggle-clustering-show-dimension="toggleClusteringShowDimension"
      @select-regression-graph="loadRegressionGraphDetail"
      @download-confusion-matrix="downloadConfusionMatrix"
      @test-connection="testRAGConnection"
      @test-prompt="testRAGPrompt"
      @rag-submit="saveRAG"
      @load-dataset-list="loadProjectDatasets"
      @add-new-data="openAddNewDataPopup"
      @on-file-input="onFileInput"
      @start-uploading="uploadFileRAG($event, popup.RAGSettingsForUpload)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { splitFullId } from '@/lib/misc.js'
import mixinPolling from '@/mixin/polling.js'
import setMountedTimer from '@/mixin/set-mounted-timer'
import { checkTrainingOrder } from '@/lib/progress-training'
import { trainedAIValidator } from '@/lib/validator/trainedAI.js'
import { convertCharts } from '@/lib/chartutils'
import { setMetricsList, getModelType } from '@/lib/trainedAI.js'
import { SHOW_TRAINED_AI_TYPE, DEEP_RECIPE_TYPE } from '@/lib/training.js'
import * as metricsDefsByModelType from '@/components/organisms/trained-ai-detail/metricsDefs'
import {
  TEXT_MINING_TYPE,
  TEXT_MINING_PAGE_TYPE,
  TEXT_MINING_PAGE_TYPE_TO_TYPE,
  textMiningResultSetting
} from '@/lib/text-mining'
import { download, base64ToBytes } from '@/lib/download'

import training from '@/components/templates/training'
import datasetMixin from './mixin/dataset'
import preventLeave from '@/components/molecules/prevent-leave'
import texts from '@/components/atoms/text'
import axios from 'axios'

export default {
  components: {
    training,
    preventLeave,
    texts
  },
  name: 'TrainingView',
  mixins: [mixinPolling, setMountedTimer, datasetMixin],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loadingInit = true
      await vm.$waitConnected()

      await vm.fetchAccountInfo()
      await vm.fetchCustomblocks()

      // 表示をリセット
      await vm.resetValidColumns()
      await vm.resetResults()
      await vm.initCharts()

      // 対象のプロジェクトと選択したデータセット・レシピIDを取得
      vm.mainData.projectId = parseInt(to.params.projectId)

      await vm.loadProjectDetail(vm.mainData.projectId)
      // 再入場時、学習設定情報のリクエスト
      if (vm.$route.params.taskId)
        await vm.getModelSettingInfo({ jobId: vm.$route.params.taskId })

      vm.progressTraining = await vm.getProgressTraining({
        projectId: vm.mainData.projectId
      })

      if (!vm.progressTraining) {
        vm.popup.showPopup.push('noTrainingData')
        return
      }

      vm.incorrectOrder = !checkTrainingOrder({
        projectId: vm.mainData.projectId,
        stage: 'training',
        progressList: vm.modelSettingList
      })

      if (vm.incorrectOrder) {
        vm.popup.showPopup.push('preventTrainingStatus')
        return
      }

      // データセットのアカウントIDが必要なので先に取得する
      vm.mainData.datasetId =
        vm.progressTraining?.preprocessingDatasetId ??
        vm.progressTraining.datasetId
      const dataset = vm.datasetList[vm.mainData.datasetId]
      vm.mainData.datasetAccountId = dataset.accountId

      // レシピIDを取得
      const recipeId = vm.progressTraining.recipeId

      // 予測する列の取得
      vm.mainData.targetColumn = vm.progressTraining.targetColumn

      // データセット詳細のロード
      if (
        !vm.trainingDatasetId ||
        vm.trainingDatasetId !== vm.mainData.datasetId ||
        !vm.trainingDatasetAccountId ||
        vm.trainingDatasetAccountId !== dataset.accountId
      ) {
        await vm.loadDatasetDetail(vm.mainData.datasetId, dataset.accountId)
        await vm.fetchDatasetInPreprocessing({
          dataId: vm.mainData.datasetId,
          accountId: dataset.accountId
        })
      }
      if (vm.trainingDatasetInPreprocess) {
        vm.showPopup('trainSettingError')
        vm.popup.trainSettingError = 'preprocessing'
      }

      // レシピの詳細を取得
      await vm.loadRecipeList()
      const recipe = Object.values(vm.recipes).find((x) => x.id === recipeId)
      const recipeAccountId = recipe.accountId
      vm.mainData.recipeId = recipeId + '-' + recipeAccountId

      const recipeType = recipe?.type
      vm.isImageType = DEEP_RECIPE_TYPE.indexOf(recipeType) !== -1

      vm.recipeDetail = await vm.loadRecipeDetail(recipeId, recipeAccountId)

      await vm.fetchLayers()
      await vm.getNumTrainedAi({
        recipeId: recipeId,
        accountId: recipeAccountId
      })

      // structuredデータの場合のみ、列チェックの要素を取得
      if (dataset.type === 'structured') {
        await vm.fetchValidColumns({
          data_id: vm.mainData.datasetId,
          data_account_id: dataset.accountId,
          recipe_id: recipe.id,
          recipe_account_id: recipe.accountId
        })
      }
      // unstructuredデータの場合のみ、サンプルデータを取得
      if (
        dataset.type === 'unstructured' &&
        vm.trainingDatasetSample?.labels == null
      ) {
        await vm.loadSampleData(vm.mainData.datasetId, dataset.accountId)
      }

      // validation 用の情報を作成
      vm.initValidateInfo()

      if (vm.$route.params.taskId) {
        vm.reenterTrainStart(vm.$route.params.taskId)
      }

      // optimizationのtutorial
      vm.checkOptimizationTutorial = vm.accountInfo.tutorialInfo.optimization

      // テスト結果タブの表示件数調整
      if (vm.isImageType) {
        vm.testDatasetInfo.limit = 15
      } else {
        vm.testDatasetInfo.limit = 50
      }

      vm.loadingInit = false
    })
  },
  async beforeRouteLeave(to, from, next) {
    if (this.preventLeave && this.trainingProgressStatus === 'finishTraining') {
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        })
        next()
      } else {
        next(false)
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      })
      next()
    }
  },
  computed: {
    headerTabs() {
      return {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      }
    },
    sidebar() {
      let status = 'setting'
      if (
        this.trainingProgressStatus === 'training' ||
        this.trainingProgressStatus === 'preparingEnvironment'
      ) {
        status = 'train'
      } else if (
        this.trainingProgressStatus === 'finishTraining' &&
        !this.isSaved
      ) {
        status = 'select'
      }
      if (this.saveStatusFlag) {
        status = 'confirm'
      }
      if (this.saveCompFlag) {
        status = 'setting'
      }
      return {
        // サイドバーに表示する情報
        activeLink: 'train',
        status: status
      }
    },
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('models', ['modelSettingList']),
    ...mapGetters('recipes', ['recipes']),
    ...mapGetters('trainedAi', ['trainedAIs']),
    ...mapGetters('trainings', [
      'training',
      'trainingProgressStatus',
      'trainingProgress',
      'trainingNumIter',
      'modelInfo',
      'elapsedTime',
      'results',
      'charts',
      'cnnMetrics',
      'bestParams',
      'bestAcc',
      'trials',
      'firstSettedBestParams',
      'firstSettedBestAcc',
      'firstSettedTrials',
      'confusionMatrixList',
      'wordCloud',
      'frequencies',
      'loadingColumns',
      'message',
      'errorManual',
      'errorDetail',
      'numCreatingtrainedAIs',
      'isStopped',
      'runningJobs',
      'finishColumns',
      'settedModelId',
      'timeseriesWarning',
      'featureImportances',
      'metrics',
      'metricsList',
      'metricsListSave',
      'featureImportancesScatter',
      'learningPredictionColumns',
      'optimizationConditions',
      'optimizationResult',
      'optimizationNumIter',
      'optimizationProgress',
      'jobId'
    ]),
    ...mapGetters('trainings', {
      rawPredictionColumnOptions: 'inputColumnsOptions'
    }),
    ...mapGetters('customblock', ['customblockList']),
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('trainingDataset', ['trainingDataset', 'trainingDatasetId', 'trainingDatasetAccountId', 'trainingDatasetSample', 'trainingDatasetInPreprocess']),

    // プロジェクトの情報
    projectInfo() {
      if (this.mainData.projectId && this.projectList) {
        return this.projectList[this.mainData.projectId]
      }
      return null
    },
    selectedRecipe() {
      return this.recipes[this.mainData.recipeId]
    },
    recipeType() {
      return this.selectedRecipe?.type
    },
    /** すべての学習結果の詳細を取得する。リスト系表示時に経路を表示するため
     * また、現在詳細をconvert_aiを通した後の新しい形式の学習済みAIの詳細に合わせている。
     * 学習済みAIの詳細ページとデータの形式を合わせるため、ここでpropertyを設定する。
     */
    trainedAiDetails() {
      const result = {}
      // 学習済みAIに紐づく情報をかき集める
      // TODO: DEEPの場合のみresults/results.jsonが保存されずに'results'の値を取得できなかったけど、保存されるようにしたから修正する
      if (this.isImageType) {
        if (this.modelInfo?.id) {
          const summaryProp = this.modelInfo
          // 利用箇所での形式にあわせるため、利用しているデータセットをArrayで設定
          summaryProp.datasets = [this.trainingDataset]
          // 重要度は空のObject
          summaryProp.featureImportance = {}
          // 精度
          const metrics = this.cnnMetrics
          if (Object.keys(this.charts).length > 0) {
            if (
              this.charts?.test.accuracy &&
              this.charts?.test.accuracy.length > 0
            ) {
              metrics.test_accuracy =
                this.charts.test.accuracy[this.charts.test.accuracy.length - 1]
              metrics.test_loss =
                this.charts.test.loss[this.charts.test.loss.length - 1]
            }
            if (
              this.charts?.train.accuracy &&
              this.charts?.train.accuracy.length > 0
            ) {
              metrics.train_accuracy =
                this.charts.train.accuracy[
                  this.charts.train.accuracy.length - 1
                ]
              metrics.train_loss =
                this.charts.train.loss[this.charts.train.loss.length - 1]
            }
          }
          summaryProp.metrics = {
            0: metrics
          }

          const modelType = getModelType(this.recipeType)
          let metricsDefs = metricsDefsByModelType[modelType](
            this.$t.bind(this)
          )

          if (modelType === 'images') {
            // 特定の精度指標が含まれるかどうかで、分類が 2 種類 or それ以上かをチェックする
            const isMultiClass = Object.keys(metrics).includes(
              'test_overall_accuracy'
            )

            // 分類の種類数に応じて精度評価を変更する。
            metricsDefs = isMultiClass
              ? metricsDefsByModelType.imagesMultiClass(this.$t.bind(this))
              : metricsDefsByModelType.images(this.$t.bind(this))
          }

          const metricsList = setMetricsList(summaryProp, metricsDefs)
          summaryProp.metricsList = metricsList

          summaryProp.type = this.recipeType

          const testData = this.results[0]?.results?.testData
          // indexを末尾につけることで特定できるようにする。deepの場合は固定値で0
          const newId = this.modelInfo.id + '-' + String(0)
          result[newId] = {
            name: this.$t('training.result.defaultName'),
            description: '',
            id: newId,
            summary: summaryProp,
            // TODO: 特別な処理をしてしまっているので、変更したい
            mapping: metrics.mapping ?? null,
            trainConfig: this.modelInfo.train_config,
            // DEEPの場合には経路は表示させないから、 `layer_info` は設定しない
            result: {
              testData,
              ...metrics
            },
            chartData: this.charts,
            featureImportanceScatter: {}
          }
        }
        // TIMEの場合は処理が異なるので変更
      } else if (
        this.$recipeType.timeExceptPreviousTransformer.includes(this.recipeType)
      ) {
        const resultsProp = this.results[0]
        const summaryProp = Object.assign({}, this.modelInfo)
        summaryProp.type = this.selectedRecipe?.type
        summaryProp.datasets = [this.trainingDataset]
        if (summaryProp.featureImportance == null) {
          summaryProp.featureImportance = {}
        }
        summaryProp.metrics = {}
        summaryProp.predictionColumns = this.learningPredictionColumns ?? []

        const configProp = Object.assign({}, this.modelInfo.train_config)
        configProp.predictionColumn = this.mainData.targetColumn

        const newId = this.modelInfo.id + '-' + 0
        result[newId] = {
          name: this.$t('training.result.defaultName'),
          description: '',
          id: newId,
          summary: summaryProp,
          mapping: this.modelInfo.mapping,
          trainConfig: configProp,
          result: resultsProp,
          featureImportanceScatter: {}
        }
      } else {
        if (!this.recipeType) {
          return {}
        }
        const latestResults = this.results.length > 0 ? this.results[0] : []
        if (latestResults.length === 0) return {}
        for (const obj of latestResults) {
          const index = obj.algo_index
          const resultsProp = obj.results
          const summaryProp = Object.assign({}, this.modelInfo)
          const type = this.selectedRecipe?.type
          summaryProp.type = type
          // 利用箇所での形式にあわせるため、利用しているデータセットをArrayで設定。
          summaryProp.datasets = [this.trainingDataset]
          // 重要度
          summaryProp.featureImportance = this.featureImportances[index] ?? {}
          // 精度
          summaryProp.metrics = this.metrics[index] ?? {}

          summaryProp.predictionColumns = this.learningPredictionColumns ?? []
          summaryProp.optimizationColumns = []

          summaryProp.metricsList = this.metricsList[index] ?? []

          summaryProp.metricsListSave = this.metricsListSave[index] ?? []

          summaryProp.is_optimization = this.checkOptimization

          // resultsPropに名前を付加

          const getCustomBlockName = (customblockId) => {
            const entry = this.customblockList?.find(
              (item) => item.customblock_id === customblockId
            )
            return entry?.name ?? this.$t('customblock.error.unknownBlock')
          }

          resultsProp?.layer_info?.forEach((item) => {
            const name = item.name
            if (name === 'customblock') {
              item.displayName = getCustomBlockName(item.customblock_id)
              return
            }
            item.displayName = this.$t('recipe.layerNames.' + name)
          })

          // indexを末尾につけることで特定できるようにする
          const newId = this.modelInfo.id + '-' + String(index)

          const configProp = Object.assign({}, this.modelInfo.train_config)
          configProp.predictionColumn = this.learningPredictionColumns

          result[newId] = {
            name: this.$t('training.result.defaultName'),
            description: '',
            id: newId,
            summary: summaryProp,
            mapping: this.modelInfo.mapping,
            trainConfig: configProp,
            result: resultsProp,
            featureImportanceScatter: this.featureImportancesScatter ?? {}
          }
          if (this.wordCloud) {
            result[newId].wordcloudImg = this.wordCloud
          }
          if (this.frequencies) {
            result[newId].frequencies = this.frequencies
          }
          if (this.trials.length > 0) {
            result[newId].trials = {
              bestAcc: this.firstSettedBestAcc,
              bestParams: this.firstSettedBestParams,
              trials: this.firstSettedTrials
            }
          }
          if (this.checkOptimization) {
            if (
              this.modelInfo?.optimization_conditions &&
              Object.keys(this.modelInfo.optimization_conditions).length > 0
            ) {
              result[newId].optimizationConditions = []
              const optimizationTime = new Date()
              const setOptimization = {
                id: this.optimizationResult.optimizationConditionsId,
                name: '最適化結果',
                description: '',
                createTime: optimizationTime,
                updateTime: optimizationTime,
                conditions: {
                  ...this.modelInfo.optimization_conditions
                    .optimizationConditions
                }
              }
              result[newId].summary.latestOptimizationConditions = {
                ...setOptimization
              }
              result[newId].optimizationConditions.push(setOptimization)

              result[newId].summary.optimizationColumns =
                this.modelInfo?.optimization_conditions?.optimizationConditions
                  ?.optimizationColumns ?? []
            }
            if (
              this.optimizationResult &&
              Object.keys(this.optimizationResult).length > 0
            ) {
              result[newId].optimizationResult = Object.assign(
                {},
                this.optimizationResult
              )
            }
          }
        }
      }
      return result
    },
    datasetList() {
      const result = {}
      this.projectList[this.mainData.projectId]?.listData?.forEach((item) => {
        result[item.id] = item
      })
      return result
    },
    /**
     * プロジェクトテンプレートにmetricsの指定がある場合はプロジェクトテンプレートに合わせる
     * それ以外の場合は、test_accuracy
     * 複数の分類の場合はtest_overall_accuracyでソートする
     */
    sortedTrainedAis() {
      if (this.trainedAiDetails === {}) {
        return []
      } else {
        const isTemplateMetrics = this.project?.template?.metrics
        const trainedAiArray = Object.values(this.trainedAiDetails)
        const typeSet = new Set()
        trainedAiArray.forEach((item) => typeSet.add(item.summary.type))
        // 複数アルゴリズムが含まれていないかのチェック
        if (typeSet.size === 1) {
          this.changeSortedFlag(true)
          // 回帰のときだけ降順/昇順を逆にする
          const sortedNum =
            typeSet[0] === 'REGRESSION' ? [-1, 0, 1] : [1, 0, -1]
          return [...trainedAiArray].sort((x, y) => {
            if (isTemplateMetrics) {
              return x.summary.metrics[isTemplateMetrics] <
                y.summary.metrics[isTemplateMetrics]
                ? sortedNum[0]
                : x.summary.metrics[isTemplateMetrics] >
                  y.summary.metrics[isTemplateMetrics]
                ? sortedNum[2]
                : sortedNum[1]
            } else if (x.summary?.metrics?.test_accuracy) {
              return x.summary.metrics.test_accuracy <
                y.summary.metrics.test_accuracy
                ? sortedNum[0]
                : x.summary.metrics.test_accuracy >
                  y.summary.metrics.test_accuracy
                ? sortedNum[2]
                : sortedNum[1]
            } else {
              return x.summary.metrics.test_overall_accuracy <
                y.summary.metrics.test_overall_accuracy
                ? sortedNum[0]
                : x.summary.metrics.test_overall_accuracy >
                  y.summary.metrics.test_overall_accuracy
                ? sortedNum[2]
                : sortedNum[1]
            }
          })
        } else {
          this.changeSortedFlag(false)
          return trainedAiArray
        }
      }
    },
    numOwntrainedAIs() {
      return this.accountInfo.usedTrainedAI
    },
    loading() {
      return this.loadingColumns || this.loadingInit || this.loadingDetail
    },
    disableClick() {
      return (
        this.loadingSave ||
        this.loadingDelete ||
        this.loadingTimeTransformerV2Setting ||
        this.data.pageBody.optimizationInfo.download.downloadLoading
      )
    },
    preventLeave: {
      get: function () {
        return (
          ['finishTraining'].indexOf(this.trainingProgressStatus) >= 0 &&
          !this.isSaved &&
          !this.isStopped &&
          this.preventLeaveFlag
        )
      },
      set: function (flag) {
        this.preventLeaveFlag = flag
      }
    },
    chartsData() {
      return convertCharts({ chartData: this.charts, themeColor: '#890491' })
    },
    checkOptimization() {
      if (!this.recipeDetail) return false
      return this.recipeDetail?.is_optimization
    },
    showAlgoIndex() {
      if (this.showTrainedAiType === SHOW_TRAINED_AI_TYPE.BEST) return 0
      if (this.showTrainedAiIndex !== null) return this.showTrainedAiIndex
      return 0
    },
    checkTimeTransformerV2() {
      if (!this.recipeType) return false
      return this.recipeType === 'TIME_TRANSFORMER2'
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo', 'updateTutorialInfo']),
    ...mapActions('recipes', ['loadRecipeList', 'fetchLayers']),
    ...mapActions('trainings', [
      'resetValidColumns',
      'resetResults',
      'fetchValidColumns',
      'initCharts',
      'trainingAsync',
      'stopTrainingAsync',
      'stopBackgroundTrainingAsync',
      'saveTrainedAi',
      'getNumTrainedAi',
      'setTimeseriesResultList',
      'reenterTrainingAsync',
      'fetchTimeseriesDetail',
      'fetchSavedTimeseriesDetail',
      'cancelWatchProgress',
      'fetchTrainedAiResult',
      'fetchValidExpressions',
      'fetchModelOptimizationResult',
      'fetchSavedTrainedAiResult',
      'setFinishColumns',
      'getModelTextMining',
      'getTimeTransformerV2BeforeSetting',
      'downloadClusteringResult',
      'fetchClusteringResult',
      'fetchClusteringDistribution',
      'fetchTrainingRegressionGraph',
      'fetchTrainingRegressionGraphDetail',
      'pollingAsync'
    ]),
    ...mapActions('models', ['getProgressTraining', 'getModelSettingInfo', 'deleteModel']),
    ...mapActions('trainedAi', [
      'fetchModelList',
      'downloadConvertDatasetResult',
      'downloadOptimizationResult',
      'updateOptimzationConditions',
      'fetchOptimizationResult',
      'getTrainedTextMining',
      'downloadTestDatasetResult',
      'fetchRegressionGraph',
      'fetchRegressionGraphDetail'
    ]),
    ...mapActions('trainedAi', {
      downloadTrainedAiClusteringResult: 'downloadClusteringResult',
      fetchTrainedAiClusteringResult: 'fetchClusteringResult',
      fetchTrainedAiClusteringDistribution: 'fetchClusteringDistribution'
    }),
    ...mapActions('project', ['loadProjectDetail', 'loadProjectList']),
    ...mapActions('customblock', ['fetchCustomblocks']),
    ...mapActions('trainingDataset', ['fetchDatasetDetail', 'fetchDatasetDetailUnstructured', 'fetchDatasetInPreprocessing']),

    inputCreateForm: function (obj) {
      const { type, form } = obj

      if (type === 'trainedAI') {
        const { id, revision } = form

        const trainedAIkeys = Object.keys(this.validateInfo.trainedAIs)
        const trainedAIs = trainedAIkeys
          .filter((trainedAIId) => trainedAIId !== id)
          .reduce((result, trainedAIId) => {
            result[trainedAIId] = this.validateInfo.trainedAIs[trainedAIId]
            return result
          }, {})

        this.popup.createInfo.trainedAIFormValidate = trainedAIValidator(
          trainedAIs,
          form
        )
        this.data.pageBody.createInfo.trainedAIFormValidate =
          trainedAIValidator(trainedAIs, form)
        if (revision) this.validateInfo.trainedAIs[id] = form
      }
    },
    async loadDatasetDetail(id, accountId) {
      if (
        this.trainingDatasetId === id ||
        this.trainingDatasetAccountId === accountId
      )
        return
      const req = {
        dataId: id,
        accountId: accountId,
        includeGraph: false,
        includeData: false
      }
      await this.fetchDatasetDetail(req)
    },
    async loadRecipeDetail(id, accountId) {
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: id,
        recipe_account_id: accountId
      })
      return res.detail
    },
    async loadDetail(fullId) {
      const [recipeId, recipeAccountId] = splitFullId(fullId)
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: recipeId,
        recipe_account_id: recipeAccountId
      })
      return res.detail
    },
    async loadSampleData(id, accountId) {
      const req = {
        dataId: id,
        accountId: accountId,
        offset: 0,
        limit: 4
      }
      await this.fetchDatasetDetailUnstructured(req)
    },
    async trainingStartButton(config) {
      if (this.recipeType === 'RAG') {
        this.showPopup('RAGConfigure')
        return
      }
      this.trainConfig = config.trainConfig
      this.saveCompFlag = false
      // これから作られる学習済みAIを全て保存できない場合は警告のポップアップ
      // すでに学習済みAIを1つも保存できない場合
      if (this.accountInfo.planDetail.numModel <= this.numOwntrainedAIs) {
        this.showPopup('upperLimitTrainedAis')
        // 1つは保存できるけど、全ては保存できない場合
      } else if (
        this.accountInfo.planDetail.numModel - this.numOwntrainedAIs <
        this.numCreatingtrainedAIs
      ) {
        this.showPopup('limitTrainedAi')
      } else {
        this.trainingStart(config)
      }
    },
    async reenterTrainStart(jobId) {
      const predictionColumn = this.mainData.targetColumn
      const isOptimization = this.$route?.query?.isOptimization
      await this.reenterTrainingAsync({
        jobId,
        predictionColumn,
        isOptimization
      })
      this.$polling_startPolling(async () => {
        await this.pollingAsync({
          jobId
        })
        if (!this.training) {
          this.$polling_stopPolling()

          /* 時系列の途中結果を取ってない場合 */
          const checkTimeWarn = this.results.some((item) => {
            return item[0]?.warn != null
          })
          if (checkTimeWarn) {
            this.getTimetransformerLearningResults({
              modelId: this.settedModelId,
              modelAccountId: this.modelInfo.accountId
            })
          }
        }
      })
    },
    async trainingStart(config) {
      // 結果表示の初期化処理
      await this.resetResults()
      await this.initCharts()
      this.resetResultData()

      this.$gtmDataLayer.sendEvent('training', 'startTrain')
      // 2回目以降の学習時にfalseに戻っているので強制設定
      this.preventLeaveFlag = true
      const payload = {
        recipeId: this.selectedRecipe.id,
        recipeAccountId: this.selectedRecipe.accountId,
        dataId: this.trainingDataset.id,
        dataAccountId: this.trainingDataset.accountId,
        trainConfig: config.trainConfig,
        projectId: this.mainData.projectId
      }
      if (this.checkOptimization) {
        payload.optimizationConditions = config.optimizationConditions
        this.data.pageBody.optimizationResult = {
          optimizationId: null,
          result: {},
          columnRanges: [],
          filters: [],
          limit: 50,
          total: 0,
          inPageNumber: 1
        }
      }
      // MFTransfromerV2の設定を固定にする
      if (this.checkTimeTransformerV2) {
        this.data.pageBody.timeTransformerV2Setting.trainingSetting =
          config.columnConditions
      }
      await this.trainingAsync(payload)

      this.$polling_startPolling(async () => {
        await this.pollingAsync({
          jobId: this.jobId
        })
        if (!this.training) {
          this.$polling_stopPolling()

          /* 時系列の途中結果を取ってない場合 */
          const checkTimeWarn = this.results.some((item) => {
            return item[0]?.warn != null
          })
          if (checkTimeWarn) {
            this.getTimetransformerLearningResults({
              modelId: this.settedModelId,
              modelAccountId: this.modelInfo.accountId
            })
          }
        }
      })
    },
    async trainingStartOver() {
      this.trainingStart(this.trainConfig)
      this.closePopup('limitTrainedAi')
    },
    async trainingStopConfirm() {
      this.$polling_stopPolling()
      await this.stopTrainingAsync()
      this.closePopup('trainingStop')
    },
    async getTimetransformerLearningResults({ modelId, accountId }) {
      if (
        this.$recipeType.timeExceptPreviousTransformer.includes(this.recipeType)
      )
        return
      const res = await this.$sendMessageAndReceive({
        action: 'getLearningResults',
        modelId: modelId,
        accountId: accountId
      })
      this.setTimeseriesResultList(res)
    },
    showPopup(e) {
      // ポップアップを表示
      this.popup.showPopup.push(e)
    },
    closePopup(e) {
      // ポップアップを閉じる
      if (e === 'noTrainingData') {
        this.$router.push({
          name: 'projectDetail',
          params: {
            projectId: this.mainData.projectId
          }
        })
        return
      } else if (e === 'trainSettingError') {
        if (this.popup.trainSettingError === 'preprocessing') {
          this.$router.push({
            name: 'trainPreprocessing',
            params: {
              projectId: this.mainData.projectId,
              type: '0',
              id: this.mainData.datasetId
            }
          })
        }
      }
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
      if (e === 'saveTrainedAi' && !this.isSaved) {
        this.saveStatusFlag = false
      } else if (e === 'saveTrainedAiComp') {
        this.saveStatusFlag = false
        this.saveCompFlag = true
      }
    },
    closeOptimizationTutorial: async function () {
      this.popup.showTutorial.optimization = false

      const tutorialInfo = Object.assign({}, this.accountInfo.tutorialInfo, {
        optimization: true
      })
      const params = {
        accountId: this.accountInfo.accountId,
        ...tutorialInfo
      }

      await this.updateTutorialInfo(params)
    },
    closeOptimizationResultTutorial: async function () {
      const tutorialInfo = Object.assign({}, this.accountInfo.tutorialInfo, {
        optimizationResult: true
      })
      const params = {
        accountId: this.accountInfo.accountId,
        ...tutorialInfo
      }

      await this.updateTutorialInfo(params)
    },
    afterEnterPage() {
      if (this.loadingInit || this.checkOptimizationTutorial) return
      if (!this.checkOptimization) return
      this.popup.showTutorial.optimization = true
    },
    showOptimizationTutorial() {
      this.popup.showTutorial.optimization = true
    },
    closePreventLeavePopup() {
      this.$refs.preventLeave.$emit('closePopup')
    },
    showGraph(e) {
      this.popup.graph = e
      this.showPopup('trainingGraph')
    },
    // 保存対象を選択するポップアップを表示
    async openSaveTrainedAi() {
      // 学習中か学習完了時に入ってくる
      if (this.trainingProgressStatus !== 'finishTraining') {
        await this.stopTrainingAsync()
      } else {
        this.showPopup('saveTrainedAi')
        this.saveStatusFlag = true
      }
    },
    // 保存の実行
    async saveConfirm(e) {
      // loading
      this.loadingSave = true

      const payload = {
        modelId: this.modelInfo.id,
        modelAccountId: this.modelInfo.accountId,
        projectId: this.mainData.projectId.toString(),
        setting: e
      }

      if (this.checkOptimization) {
        payload.setting[0].optimizationInfo = {
          name: this.sortedTrainedAis[0].optimizationConditions[0].name,
          description:
            this.sortedTrainedAis[0].optimizationConditions[0].description
        }
      }

      this.savedAis = (await this.saveTrainedAi(payload)).id
      // 学習済みAIの保持情報更新
      this.fetchAccountInfo()
      this.loadProjectDetail(this.mainData.projectId)
      this.sendSaveGaEvent(e)
      this.isSaved = true
      this.closePopup('saveTrainedAi')
      this.loadingSave = false
      this.$nextTick(() => {
        this.showPopup('saveTrainedAiComp')
      })
    },
    sendSaveGaEvent(e) {
      this.sortedTrainedAis.forEach((item) => {
        if (e.some((event) => event.id === item.id)) {
          const timeTypes =
            item.summary.type === 'TIME' ||
            item.summary.type === 'TIME_TRANSFORMER' ||
            item.summary.type === 'TIME_TRANSFORMER2'
          if (timeTypes) {
            const metrics = item.result?.rmse ?? null
            this.$gtmDataLayer.sendEventCategory(
              'saveAiMetrics',
              item.summary.type,
              metrics ?? '精度が表示されません'
            )
          } else {
            const metrics = item.summary?.metricsList ?? []

            const res = Object.values(metrics)
              .map((item) => {
                return item.value
              })
              .join()

            this.$gtmDataLayer.sendEventCategory(
              'saveAiMetrics',
              item.summary.type,
              res.length > 0 ? res : '精度が表示されません'
            )
          }
        }
      })
      this.$gtmDataLayer.sendEvent('training', 'saveTrainedAi')
    },
    async deleteConfirm() {
      this.loadingDelete = true
      // 完了した学習済みAIの情報を削除
      await this.deleteModel({
        modelId: this.modelInfo.id,
        accountId: this.modelInfo.accountId
      })
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      await this.resetResults()
      await this.initCharts()
      this.loadingDelete = false
      this.closePopup('saveTrainedAi')
      this.closePopup('deleteTrainedAi')
    },
    backgroundTrainingStopPopup() {
      this.showPopup('backgroundTrainingStop')
    },
    async backgroundTrainingStopConfirm() {
      await this.stopBackgroundTrainingAsync()
      this.closePopup('backgroundTrainingStop')
    },
    async moveInference() {
      let name = ''
      let params = ''
      let query = {}
      if (this.mainData.projectId) {
        name = 'inferenceProject'
        params = {
          projectId: this.mainData.projectId
        }
        query = {
          // TODO: https://trello.com/c/eQRDpabU
          trainedAiId:
            this.savedAis?.length === 1 ? this.savedAis[0] : undefined
        }
      } else {
        name = 'inference'
        params = {}
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.$router.push({
        name: name,
        params: params,
        query
      })
    },
    moveThisProject() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.$router.push({
        name: 'projectDetail',
        params: {
          projectId: this.mainData.projectId
        }
      })
    },
    moveOptimization() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.$router.push({
        name: 'inferenceProject',
        params: {
          projectId: this.mainData.projectId
        },
        query: {
          // TODO: https://trello.com/c/eQRDpabU
          trainedAiId:
            this.savedAis?.length === 1 ? this.savedAis[0] : undefined,
          isOptimization: true
        }
      })
    },
    reTrain() {
      this.closePopup('saveTrainedAiComp')
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    changeSortedFlag(flag) {
      this.sortedFlag = flag
    },
    initValidateInfo: async function () {
      await this.loadProjectDetail(this.mainData.projectId)

      const trainedAiList = this.projectList[this.mainData.projectId].listAIs
      this.validateInfo.trainedAIs = trainedAiList.reduce(
        (result, trainedAI) => {
          result[trainedAI.id] = trainedAI
          return result
        },
        {}
      )

      this.validateInfo.resetKey = 0
    },
    saveThisTrainedAI: function () {
      this.validateInfo.resetKey = this.validateInfo.resetKey + 1
    },
    notPrevent() {
      this.preventLeave = false
    },
    pageBack() {
      this.$router.push({
        name: 'trainRecipeList',
        params: {
          projectId: this.mainData.projectId.toString()
        }
      })
    },
    async changeColumn(column) {
      const targetIndex = this.finishColumns.indexOf(column)
      if (this.data.pageBody.selectedColumnIndex === targetIndex) return
      if (targetIndex === -1) return

      this.data.pageBody.selectedColumnIndex = targetIndex

      this.loadChangeColumn = true
      this.testDatasetInfo.waitTable = true
      if (
        this.$recipeType.timeExceptPreviousTransformer.includes(this.recipeType)
      ) {
        if (!this.isSaved) {
          await this.fetchTimeseriesDetail({
            id: this.settedModelId,
            targetIndex
          })
        } else {
          await this.fetchSavedTimeseriesDetail({
            id: this.savedAis[0], // 時系列は一つしかないので０で投げる
            targetIndex
          })
        }
      } else {
        // 表示する列によって設定可否が変わるから、列を変更するときは閾値と陽性判定は初期化する
        await this.getResultDetail({
          targetIndex,
          threshold: 0.5,
          reversePositive: false
        })
        await this.getResultDetailByChangeTrainedAi()
      }
      this.threshold = 0.5
      this.reversePositive = false
      this.loadChangeColumn = false
      this.testDatasetInfo.waitTable = false
    },
    async checkExpressions({ expressions, columns }) {
      if (this.data.pageBody.optimizationInfo.loadCheckExpressions) return
      this.data.pageBody.optimizationInfo.loadCheckExpressions = true
      const res = await this.fetchValidExpressions({ expressions, columns })
      this.data.pageBody.optimizationInfo.validExpressions = res
      this.data.pageBody.optimizationInfo.loadCheckExpressions = false
    },
    // 最適化の名前変更
    async saveEditOptimizationForm(e) {
      this.sortedTrainedAis[0].optimizationConditions[0].name = e.name
      this.sortedTrainedAis[0].optimizationConditions[0].description =
        e.description
      if (this.isSaved) {
        this.data.pageBody.optimizationInfo.loadOptimizationConditionsUpdate = true
        const payload = {
          name: e.name,
          description: e.description,
          optimizationConditionsId:
            this.data.pageBody.optimizationResult.optimizationId
        }
        await this.updateOptimzationConditions(payload)
        this.data.pageBody.optimizationInfo.loadOptimizationConditionsUpdate = false
      }
    },
    async loadOptimizationResult(id, config) {
      if (!config) return
      this.data.pageBody.optimizationInfo.download.downloadComp = false
      const targetInfo = this.data.pageBody.optimizationInfo
      const targetResult = this.data.pageBody.optimizationResult
      if (targetInfo.loadOptimizationPaging) return
      targetInfo.loadOptimizationPaging = true

      targetResult.optimizationId = id
      targetResult.filters = config.filters
      targetResult.inPageNumber = config.inPageNumber

      const payload = {
        optimizationConditionsId: targetResult.optimizationId,
        limit: targetResult.limit,
        resultRanges: targetResult.filters,
        offset: (targetResult.inPageNumber - 1) * targetResult.limit
      }

      let res
      if (!this.isSaved) {
        payload.modelId = this.modelInfo.id
        res = await this.fetchModelOptimizationResult(payload)
      } else {
        // TODO: https://trello.com/c/eQRDpabU
        payload.trainedAiId = this.savedAis[0]
        res = await this.fetchOptimizationResult(payload)
      }

      targetResult.result = res.result
      targetResult.total = res.total

      targetInfo.loadOptimizationPaging = false
    },
    async changePageOptimization({ id, page }) {
      const config = {
        inPageNumber: page,
        filters: this.data.pageBody.optimizationResult.filters
      }
      await this.loadOptimizationResult(id, config)
    },
    async changePageTestDataset(index) {
      if (this.testDatasetInfo.waitTable) return
      const targetIndex = this.data.pageBody.selectedColumnIndex

      this.loadChangeColumn = true
      try {
        this.testDatasetInfo.waitTable = true
        this.testDatasetInfo.inPageNumber = index

        // 学習済みAI詳細情報に、テスト結果の情報が含まれている
        await this.getResultDetail({ targetIndex, pageIndex: index })
      } finally {
        this.loadChangeColumn = false
        this.testDatasetInfo.waitTable = false
      }
    },
    async changeFilterValue({ id, filter }) {
      const filters = this.data.pageBody.optimizationResult.filters
      const checkSettedFilter = filters.findIndex((item) => {
        return item.name === filter.name
      })

      if (checkSettedFilter >= 0) {
        filters[checkSettedFilter] = filter
      } else {
        filters.push(filter)
      }

      const config = {
        inPageNumber: 1,
        filters: filters
      }
      await this.loadOptimizationResult(id, config)
    },
    async changeTabOptimization() {
      const targetResult = this.data.pageBody.optimizationResult
      targetResult.optimizationId =
        this.optimizationResult.optimizationConditionsId
      targetResult.result = this.optimizationResult.result
      targetResult.filters = []
      targetResult.inPageNumber = 1
      targetResult.total = this.optimizationResult.total
    },
    async downloadResult(encoding) {
      if (this.recipeType === 'CONVERT_DATASET') {
        this.downloadResultConvert(encoding)
      } else {
        this.downloadResultOptimization(encoding)
      }
    },
    async downloadResultConvert(encoding) {
      if (this.data.pageBody.optimizationInfo.download.downloading) return
      this.data.pageBody.optimizationInfo.download.downloading = true
      this.data.pageBody.optimizationInfo.download.downloadComp = false

      // すぐにダウンロードできるものは、黒い背景が一瞬しか見えないので、setTimeoutを入れている
      this.timeoutDownload = window.setTimeout(
        function () {
          this.data.pageBody.optimizationInfo.download.downloadLoading = true
        }.bind(this),
        100
      )

      const payload = {
        encoding: encoding,
        targetIndex: 0,
        algoIndex: 0
      }

      if (!this.isSaved) {
        payload.modelId = this.modelInfo.id
      } else {
        // TODO: https://trello.com/c/eQRDpabU
        payload.trainedAiId = this.savedAis[0]
      }

      try {
        this.downloadConvertDatasetResult(payload)
      } finally {
        this.data.pageBody.optimizationInfo.download.downloading = false
        window.clearTimeout(this.timeoutDownload)
        this.data.pageBody.optimizationInfo.download.downloadLoading = false
        this.data.pageBody.optimizationInfo.download.downloadComp = true
      }
    },
    async downloadResultOptimization(encoding) {
      if (this.data.pageBody.optimizationInfo.download.downloading) return
      this.data.pageBody.optimizationInfo.download.downloading = true
      this.data.pageBody.optimizationInfo.download.downloadComp = false

      // すぐにダウンロードできるものは、黒い背景が一瞬しか見えないので、setTimeoutを入れている
      this.timeoutDownload = window.setTimeout(
        function () {
          this.data.pageBody.optimizationInfo.download.downloadLoading = true
        }.bind(this),
        100
      )

      if (!this?.data?.pageBody?.optimizationResult?.optimizationId) {
        this.data.pageBody.optimizationResult.optimizationId =
          this.modelInfo.optimization_conditions.id
      }

      const payload = {
        optimizationConditionsId:
          this.data.pageBody.optimizationResult.optimizationId,
        encoding: encoding
      }

      if (!this.isSaved) {
        payload.modelId = this.modelInfo.id
      } else {
        // TODO: https://trello.com/c/eQRDpabU
        payload.trainedAiId = this.savedAis[0]
      }

      try {
        this.downloadOptimizationResult(payload)
      } finally {
        this.data.pageBody.optimizationInfo.download.downloading = false
        window.clearTimeout(this.timeoutDownload)
        this.data.pageBody.optimizationInfo.download.downloadLoading = false
        this.data.pageBody.optimizationInfo.download.downloadComp = true
      }
    },
    async downloadTestDataset(encoding) {
      if (this.testDatasetInfo.download.downloading) return
      this.testDatasetInfo.download.downloading = true
      this.testDatasetInfo.download.downloadComp = false

      const payload = {
        encoding: encoding,
        columnIndex: this.data.pageBody.selectedColumnIndex,
        algoIndex: this.showAlgoIndex,
        isImage: this.isImageType
      }

      if (!this.isSaved) {
        payload.modelId = this.modelInfo.id
      } else {
        // TODO: https://trello.com/c/eQRDpabU
        payload.trainedAiId = this.savedAis[0]
      }

      try {
        await this.downloadTestDatasetResult(payload)
      } finally {
        this.testDatasetInfo.download.downloading = false
        this.testDatasetInfo.download.downloadLoading = false
        this.testDatasetInfo.download.downloadComp = true
      }
    },
    async downloadConfusionMatrix(e) {
      let payload
      if (this.isSaved) {
        payload = {
          action: 'downloadConfusionMatrix',
          trainedAiId: this.savedAis[this.showAlgoIndex],
          columnIndex: this.data.pageBody.selectedColumnIndex,
          encoding: e.encoding,
          reversePositive: this.reversePositive
        }
      } else {
        payload = {
          action: 'downloadConfusionMatrix',
          modelId: this.settedModelId,
          columnIndex: this.data.pageBody.selectedColumnIndex,
          algoIndex: this.showAlgoIndex ?? 0,
          encoding: e.encoding,
          reversePositive: this.reversePositive
        }
      }
      const res = await this.$sendMessageAndReceive(payload)
      const decoded = base64ToBytes(res.file)
      download(decoded, 'confusion_matrix.csv')
    },
    invalidFinishAi(trainingStatus) {
      // 時系列予測の場合に `trainedAiDetails` にwarn情報が入ることがあるが、これは別処理で考慮されているからこれが入っていても正常とみなす
      return (
        trainingStatus === 'finishTraining' &&
        this.trainedAiDetails &&
        Object.values(this.trainedAiDetails).length === 0
      )
    },
    async changeThreshold(threshold) {
      this.threshold = threshold
    },
    async changePositive(reversePositive) {
      this.reversePositive = reversePositive
    },

    // テキストマイニングのパラメータの初期値設定
    setTextMiningParams() {
      this.data.pageBody.textMining.columns =
        this.modelInfo?.textMining?.columns ?? []
      const body = this.data.pageBody.textMining
      const params = body.params

      // 感情分析がある場合は、ソート条件を感情分析の信頼度にする
      if (
        this.modelInfo?.textMining?.typeList &&
        this.modelInfo?.textMining.typeList[TEXT_MINING_TYPE.SENTIMENTS]
      ) {
        params[TEXT_MINING_PAGE_TYPE.TEXT_LIST].sentiments = 'negative'
        params[TEXT_MINING_PAGE_TYPE.TEXT_LIST].sortTarget = 'proba'
      }

      // CSVでの学習の場合自然言語の列の0番目を取得する情報の初期値としてセットする
      if (body.columns && body.columns.length > 0) {
        params.columnName = body.columns[0]
      }
    },
    // 各種テキストマイニングの結果取得
    async loadTextMining({ pageType, value, columnName }) {
      const textMiningTarget = this.data.pageBody.textMining
      if (textMiningTarget.waitTextMiningLoading) return

      textMiningTarget.waitTextMiningLoading = true

      // 頻度表示またはワードクラウドの画面で列名以外の変更があった場合はアクションを叩かない
      if (
        pageType === TEXT_MINING_PAGE_TYPE.FREQUENCIES_WORDCLOUD &&
        value.count !== textMiningTarget.params[pageType].count
      ) {
        textMiningTarget.params[pageType] = value
        textMiningTarget.waitTextMiningLoading = false
        return
      }

      // テキストごとのトピックを見る際にどのテキストを使用するのか、指定がない場合は0番目のテキストの結果をパラメータにする
      if (
        pageType === TEXT_MINING_PAGE_TYPE.TEXT_TOPIC &&
        value.targetName == null
      ) {
        if (
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST]?.datas == null ||
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST].datas.length === 0
        ) {
          const textListLoadTypes =
            TEXT_MINING_PAGE_TYPE_TO_TYPE[TEXT_MINING_PAGE_TYPE.TEXT_LIST]
          const params =
            textMiningTarget.params[TEXT_MINING_PAGE_TYPE.TEXT_LIST]

          // 保存前はmodelIdで、保存後は学習済みAIのIDでそれぞれアクションを叩く
          let textList = {}
          if (!this.isSaved) {
            textList = await this.getModelTextMining({
              loadType: textListLoadTypes[0],
              payload: {
                ...params,
                columnName,
                id: this.settedModelId
              }
            })
          } else {
            textList = await this.getTrainedTextMining({
              loadType: textListLoadTypes[0],
              payload: {
                ...params,
                columnName,
                id: this.savedAis[0] // 複数のアルゴリズムがあっても、すべてのテキストマイニングの結果は同じなので、保存されたAIの先頭のもので取得する
              }
            })
          }
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST] = textList
        }
        value.targetName =
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST].datas[0].fileName
      }
      const loadTypes = TEXT_MINING_PAGE_TYPE_TO_TYPE[pageType]

      for (const loadType of loadTypes) {
        // パラメータに変更がない場合かつ、該当の結果がある場合、アクションを叩かない
        if (
          columnName === textMiningTarget.params.columnName &&
          !(textMiningTarget[loadType] == null)
        ) {
          const checkDifferent = Object.entries(
            textMiningTarget.params[pageType]
          ).some(([key, val]) => {
            return value[key] !== val
          })
          if (!checkDifferent) continue
        }
        // 保存前はmodelIdで、保存後は学習済みAIのIDでそれぞれアクションを叩く
        let res = {}
        textMiningTarget[loadType] = null
        if (!this.isSaved) {
          res = await this.getModelTextMining({
            loadType,
            payload: {
              ...value,
              columnName,
              id: this.settedModelId
            }
          })
        } else {
          res = await this.getTrainedTextMining({
            loadType,
            payload: {
              ...value,
              columnName,
              id: this.savedAis[0] // 複数のアルゴリズムがあっても、すべてのテキストマイニングの結果は同じなので、保存されたAIの先頭のもので取得する
            }
          })
        }
        textMiningTarget[loadType] = res
      }
      textMiningTarget.params[pageType] = value
      textMiningTarget.params.columnName = columnName
      textMiningTarget.waitTextMiningLoading = false
    },
    // テキストマイニングのサイドバーの切り替え
    changeTabTextMining(pageType) {
      this.data.pageBody.textMining.activeTab = pageType
    },
    updateShowType(showType) {
      this.showTrainedAiType = showType
    },
    async updateShowTrainedAi(showTrainedAiIndex) {
      this.showTrainedAiIndex = showTrainedAiIndex
      await this.getResultDetailByChangeTrainedAi()
    },
    resetResultData() {
      // 学習再開時に初期化する必要があるdataの初期化
      this.data.pageBody.selectedColumnIndex = 0
      this.testDatasetInfo.inPageNumber = 1
      this.isSaved = false
      this.threshold = 0.5
      this.reversePositive = false
      this.showTrainedAiType = SHOW_TRAINED_AI_TYPE.BEST
      this.showTrainedAiIndex = null
    },
    async showTimeTransformerV2BeforeSetting() {
      this.loadingTimeTransformerV2Setting = true
      if (
        this.popup.timeTransformerV2Settings == null ||
        this.popup.timeTransformerV2Settings.length === 0
      ) {
        this.popup.timeTransformerV2Settings =
          await this.getTimeTransformerV2BeforeSetting(
            this.mainData.projectId.toString()
          )
      }
      this.showPopup('timeTransformerV2BeforeSetting')
      this.loadingTimeTransformerV2Setting = false
    },
    selectTimeTransformerV2Setting(settings) {
      this.data.pageBody.timeTransformerV2Setting.selectedPreviousSetting = {}
      this.data.pageBody.timeTransformerV2Setting.selectedPreviousSetting =
        settings
      this.closePopup('timeTransformerV2BeforeSetting')
    },
    inputClusteringSetting({ value, type }) {
      const settingTarget = this.data.pageBody.clusteringSetting.setting
      switch (type) {
        case 'changeClass':
          if (settingTarget.resultId === value) return
          settingTarget.resultId = value
          settingTarget.inPageNumber = 1
          break
        case 'changeSort':
          if (settingTarget.sort === value) return
          settingTarget.sort = value
          settingTarget.inPageNumber = 1
          break
        case 'changePage':
          if (settingTarget.inPageNumber === value) return
          settingTarget.inPageNumber = value
          break
      }
      if (value != null && value !== '') {
        this.loadClusteringResult(type)
      }
    },
    async loadClusteringResult(type) {
      const clusteringSetting = this.data.pageBody.clusteringSetting.setting

      this.data.pageBody.clusteringSetting.loadingTable = true

      const offset =
        clusteringSetting.inPageNumber * clusteringSetting.limit -
        clusteringSetting.limit
      const payload = {
        resultId: clusteringSetting.resultId,
        offset,
        limit: clusteringSetting.limit,
        sort: clusteringSetting.sort
      }

      if (!this.isSaved) {
        payload.id = this.settedModelId
        this.data.pageBody.clusteringResult = await this.fetchClusteringResult(
          payload
        )
      } else {
        payload.id = this.savedAis[this.showAlgoIndex]
        this.data.pageBody.clusteringResult =
          await this.fetchTrainedAiClusteringResult(payload)
      }

      if (type === 'changeClass') {
        const distributionTarget =
          this.data.pageBody.clusteringSetting.distributionSetting

        const array = [...Array(3)].map((_, i) => i)
        await Promise.all(
          array.map(async (index) => {
            if (
              distributionTarget[index].xTarget != null &&
              distributionTarget[index].yTarget != null
            ) {
              const func = await this.loadClusteringDistribution(index)
              return func
            } else {
              return null
            }
          })
        )
      }

      this.data.pageBody.clusteringSetting.loadingTable = false
    },
    changeClusteringDistributionColumn({ value, target, index }) {
      const clusteringSetting = this.data.pageBody.clusteringSetting

      const firstCheck =
        clusteringSetting.distributionSetting[index][target] !== value
      clusteringSetting.distributionSetting[index][target] = value

      if (
        clusteringSetting.distributionSetting[index].xTarget == null &&
        clusteringSetting.distributionSetting[index].yTarget == null
      )
        return

      let checkX = false
      let checkY = false
      if (clusteringSetting.setting.showDimension) {
        checkX = this.sortedTrainedAis[
          this.showAlgoIndex
        ].summary.clusteringInfo.dimensionList.includes(
          clusteringSetting.distributionSetting[index].xTarget
        )
        checkY = this.sortedTrainedAis[
          this.showAlgoIndex
        ].summary.clusteringInfo.dimensionList.includes(
          clusteringSetting.distributionSetting[index].yTarget
        )
      } else {
        checkX = this.sortedTrainedAis[
          this.showAlgoIndex
        ].summary.clusteringInfo.distributionInputList.includes(
          clusteringSetting.distributionSetting[index].xTarget
        )
        checkY = this.sortedTrainedAis[
          this.showAlgoIndex
        ].summary.clusteringInfo.distributionInputList.includes(
          clusteringSetting.distributionSetting[index].yTarget
        )
      }

      if (checkX && checkY && firstCheck) {
        this.loadClusteringDistribution(index)
      }
    },
    toggleClusteringShowDimension(bool) {
      this.data.pageBody.clusteringSetting.setting.showDimension = bool
      // 次元を表示するかどうかで、散布図の表示時に選択できる項目がかわるので、散布図の表示をリセットする
      this.data.pageBody.clusteringDistributions.splice(0)
      this.data.pageBody.clusteringSetting.distributionSetting =
        this.data.pageBody.clusteringSetting.distributionSetting.map(
          (setting) => {
            return {
              xTarget: null,
              yTarget: null
            }
          }
        )
    },
    async loadClusteringDistribution(index) {
      const clusteringSetting = this.data.pageBody.clusteringSetting

      this.data.pageBody.clusteringSetting.loadingDistribution = true

      const payload = {
        resultId: clusteringSetting.setting.resultId,
        xTarget: clusteringSetting.distributionSetting[index].xTarget,
        yTarget: clusteringSetting.distributionSetting[index].yTarget
      }

      let res = null

      if (!this.isSaved) {
        payload.id = this.settedModelId
        res = await this.fetchClusteringDistribution(payload)
      } else {
        payload.id = this.savedAis[this.showAlgoIndex]
        res = await this.fetchTrainedAiClusteringDistribution(payload)
      }

      this.$set(this.data.pageBody.clusteringDistributions, index, res)

      this.data.pageBody.clusteringSetting.loadingDistribution = false
    },
    async downloadClustering({ type, encoding }) {
      const clusteringSetting = this.data.pageBody.clusteringSetting
      if (clusteringSetting.download.downloading) return
      clusteringSetting.download.downloading = true
      clusteringSetting.download.downloadComp = false

      const payload = {
        encoding: encoding
      }

      if (type === 'filtered') {
        payload.resultId = clusteringSetting.setting.resultId
      }

      try {
        if (!this.isSaved) {
          payload.id = this.settedModelId
          await this.downloadClusteringResult({ type, payload })
        } else {
          payload.id = this.savedAis[this.showAlgoIndex]
          await this.downloadTrainedAiClusteringResult({ type, payload })
        }
      } finally {
        clusteringSetting.download.downloading = false
        clusteringSetting.download.downloadLoading = false
        clusteringSetting.download.downloadComp = true
      }
    },

    // 保存前後問わず、学習済みAI詳細情報を取得する。ただし、時系列用の特殊処理は除外する
    async getResultDetail({
      targetIndex,
      threshold = null,
      reversePositive = null,
      pageIndex = null
    }) {
      const payload = {
        threshold: threshold === null ? this.threshold : threshold,
        reversePositive:
          reversePositive === null ? this.reversePositive : reversePositive,
        limit: this.testDatasetInfo.limit,
        offset:
          pageIndex === null
            ? (this.testDatasetInfo.inPageNumber - 1) *
              this.testDatasetInfo.limit
            : (pageIndex - 1) * this.testDatasetInfo.limit,
        isImage: this.isImageType
      }

      if (!this.isSaved) {
        payload.targetIndex = targetIndex
        payload.algoIndex = this.showAlgoIndex
        payload.id = this.settedModelId
        payload.accountId = this.accountInfo.accountId
        await this.fetchTrainedAiResult(payload)
      } else {
        payload.columnIndex = targetIndex
        payload.id = this.savedAis[this.showAlgoIndex]
        await this.fetchSavedTrainedAiResult(payload)
      }
    },
    // getResultDetailで取得していないが、AIの変更時に取得する情報がある場合に実行される
    async getResultDetailByChangeTrainedAi() {
      if (this.recipeType === 'REGRESSION') {
        await this.loadRegressionGraph()
      }
    },
    async loadRegressionGraph() {
      this.data.pageBody.regressionGraph.error = null
      this.data.pageBody.regressionGraph.loadGraph = true
      this.data.pageBody.regressionGraph.graphDetail = {}
      this.data.pageBody.regressionGraph.selectedRegressionData = null
      let res
      if (this.isSaved) {
        res = await this.fetchRegressionGraph({
          trainedAiId: this.savedAis[this.showAlgoIndex],
          columnIndex: this.data.pageBody.selectedColumnIndex
        })
      } else {
        res = await this.fetchTrainingRegressionGraph({
          modelId: this.modelInfo.id,
          columnIndex: this.data.pageBody.selectedColumnIndex,
          algoIndex: this.showAlgoIndex
        })
      }

      if (res === 27600) {
        this.data.pageBody.regressionGraph.error = this.$t(
          'trainedAi.regressionGraph.error.NOT_FOUND_RESIDUAL_GRAPH_INFO'
        )
      } else {
        this.data.pageBody.regressionGraph.graph = res
      }
      this.data.pageBody.regressionGraph.loadGraph = false
    },
    async loadRegressionGraphDetail(event) {
      this.data.pageBody.regressionGraph.loadGraphDetail = true
      this.data.pageBody.regressionGraph.selectedRegressionData = event.index
      let res
      if (this.isSaved) {
        res = await this.fetchRegressionGraphDetail({
          trainedAiId: this.savedAis[this.showAlgoIndex],
          columnIndex: this.data.pageBody.selectedColumnIndex,
          index: event.index
        })
      } else {
        res = await this.fetchTrainingRegressionGraphDetail({
          modelId: this.modelInfo.id,
          columnIndex: this.data.pageBody.selectedColumnIndex,
          index: event.index,
          algoIndex: this.showAlgoIndex
        })
      }

      this.data.pageBody.regressionGraph.graphDetail = res
      this.data.pageBody.regressionGraph.loadGraphDetail = false
    },
    async testRAGConnection(e) {
      try {
        const result = await axios.post('/api/mf/ai/test-generative-ai', {
          serviceType: e.serviceType,
          deploymentName: e.deploymentName,
          apiKey: e.apiKey,
          endpoint: e.endpoint,
          apiVersion: e.apiVersion
        })
        this.RAGresponse.test.ok = result.status === 200
      } catch (_e) {
        this.RAGresponse.test.ok = false
      }
    },
    async testRAGPrompt(e) {
      try {
        const result = await axios.post('/api/mf/ai/test-rag', {
          projectId: this.mainData.projectId,

          serviceType: e.serviceType,
          deploymentName: e.deploymentName,
          apiKey: e.apiKey,
          endpoint: e.endpoint,
          apiVersion: e.apiVersion,

          temperture: e.temperture,
          topP: e.topP,
          maxTokens: e.maxTokens,
          presencePenalty: e.presencePenalty,
          frequencyPenalty: e.frequencyPenalty,
          stop: e.stop,

          prompt: e.prompt,
          question: e.question,
          ragResult: e.ragResult
        })
        this.RAGresponse.promptTest.ok = result.status === 200
        this.RAGresponse.promptTest.result = result.data
      } catch (_e) {
        this.RAGresponse.promptTest.ok = false
        this.RAGresponse.promptTest.result = ''
      }
    },
    async saveRAG(e) {
      const result = await axios.post('/api/mf/ai/register-rag', {
        projectId: this.mainData.projectId,
        recipeId: this.mainData.recipeId,

        serviceType: e.serviceType,
        deploymentName: e.deploymentName,
        apiKey: e.apiKey,
        endpoint: e.endpoint,
        apiVersion: e.apiVersion,

        temperture: e.temperture,
        topP: e.topP,
        maxTokens: e.maxTokens,
        presencePenalty: e.presencePenalty,
        frequencyPenalty: e.frequencyPenalty,
        stop: e.stop,
        prompt: e.prompt
      })
      this.closePopup('RAGConfigure')
      this.$router.push({
        name: 'serviceProjectList',
        params: {
          projectId: this.mainData.projectId
        }
      })
    },
    openAddNewDataPopup(e) {
      this.popup.RAGSettingsForUpload = e
      this.showPopup('addNewData')
    }
  },
  data() {
    return {
      mainData: {
        datasetId: '',
        datasetAccountId: '',
        recipeId: '',
        targetColumn: null,
        projectId: ''
      },
      datasetDetail: {},
      recipeDetail: {},
      sampleData: null,
      RAGresponse: {
        test: {
          ok: null
        },
        promptTest: {
          ok: null,
          result: null
        }
      },
      data: {
        pageBody: {
          createInfo: {
            trainedAIFormValidate: {
              duplicate: undefined
            }
          },
          selectedColumnIndex: 0,
          optimizationInfo: {
            validExpressions: [],
            loadCheckExpressions: false,
            optimizationComp: false,
            loadOptimizationPaging: false,
            loadOptimizationDetail: false,
            loadOptimizationConditionsUpdate: false,
            download: {
              downloading: false,
              downloadLoading: false,
              downloadComp: false
            }
          },
          optimizationResult: {
            optimizationId: null,
            result: {},
            columnRanges: [],
            filters: [],
            limit: 50,
            total: 0,
            inPageNumber: 1
          },
          inferenceListInfo: {
            loading: false,
            inferenceList: []
          },
          textMining: textMiningResultSetting(),
          timeTransformerV2Setting: {
            selectedPreviousSetting: [],
            trainingSetting: []
          },
          clusteringSetting: {
            setting: {
              resultId: null,
              inPageNumber: 1,
              limit: 50,
              sort: 'classAsc',
              showDimension: false
            },
            loadingTable: false,
            loadingDistribution: false,
            download: {
              downloading: false,
              downloadLoading: false,
              downloadComp: false
            },
            distributionSetting: [
              {
                xTarget: null,
                yTarget: null
              },
              {
                xTarget: null,
                yTarget: null
              },
              {
                xTarget: null,
                yTarget: null
              }
            ]
          },
          clusteringResult: {
            inputColumns: [],
            classes: [],
            total: null,
            list: [],
            listIndexClass: []
          },
          clusteringDistributions: [],
          regressionGraph: {
            graph: {
              list: [],
              dataCount: null
            },
            graphDetail: {},
            selectedRegressionData: null,
            loadGraph: false,
            loadGraphDetail: false,
            error: null
          }
        }
      },
      popup: {
        createInfo: {
          trainedAIFormValidate: {
            duplicate: undefined
          }
        },
        showPopup: [],
        graph: null,
        trainSettingError: null,
        showTutorial: {
          optimization: false
        },
        timeTransformerV2Settings: [],
        RAGSettingsForUpload: {}
      },
      trainConfig: {},
      loadingInit: false,
      loadingDetail: false,
      loadingSave: false,
      loadingDelete: false,
      loadChangeColumn: false,
      loadingReGet: false,
      loadingTimeTransformerV2Setting: false,
      isSaved: false,
      preventLeaveFlag: true,
      sortedFlag: true,
      progressTraining: null,
      validateInfo: {
        resetKey: 0,
        trainedAIs: {}
      },
      saveStatusFlag: false,
      saveCompFlag: false,
      savedAis: null,
      incorrectOrder: null,
      checkOptimizationTutorial: false,
      threshold: 0.5,
      reversePositive: false,
      showTrainedAiType: SHOW_TRAINED_AI_TYPE.BEST,
      showTrainedAiIndex: null,
      testDatasetInfo: {
        limit: 50, // 推論結果の一度に帰ってくる件数
        inPageNumber: 1, // テストデータに対する推論結果のページ番号（1始まり）
        waitTable: false,
        download: {
          downloading: false,
          downloadLoading: false,
          downloadComp: false
        }
      },
      isImageType: false
    }
  },
  watch: {
    async trainingProgressStatus(newVal) {
      if (this.invalidFinishAi(newVal)) {
        try {
          this.loadingReGet = true
          // もう一回学習済みAIの情報を取得して、正常な状態であるかを確認する
          if (
            this.$recipeType.timeExceptPreviousTransformer.includes(
              this.recipeType
            )
          ) {
            await this.fetchTimeseriesDetail({
              id: this.settedModelId,
              targetIndex: 0
            })
          } else if (this.recipeType === 'TIME_TRANSFORMER') {
            await this.getTimetransformerLearningResults({
              modelId: this.settedModelId,
              modelAccountId: this.modelInfo.accountId
            })
          } else {
            await this.fetchTrainedAiResult({
              id: this.settedModelId,
              targetIndex: 0,
              algoIndex: 0,
              accountId: this.accountInfo.accountId
            })
          }
          if (this.invalidFinishAi(newVal)) {
            this.showPopup('errorTraining')
          } else {
            this.setFinishColumns()
          }
        } finally {
          this.loadingReGet = false
        }
      }
    },
    async timeseriesWarning(newVal) {
      if (newVal) {
        await this.fetchTimeseriesDetail({
          id: this.settedModelId,
          targetIndex: 0
        })
      }
    },
    optimizationResult: {
      async handler(newVal) {
        if (newVal?.optimizationConditionsId) {
          const targetResult = this.data.pageBody.optimizationResult
          targetResult.columnRanges = newVal.columnRanges
          targetResult.total = newVal.total
          targetResult.optimizationId = newVal.optimizationConditionsId
          const config = {
            inPageNumber: 1,
            filters: []
          }
          await this.loadOptimizationResult(targetResult.optimizationId, config)
        }
      },
      deep: true
    },
    async threshold(threshold) {
      const targetIndex = this.data.pageBody.selectedColumnIndex

      this.loadChangeColumn = true
      try {
        this.testDatasetInfo.waitTable = true
        await this.getResultDetail({ targetIndex, threshold })
      } finally {
        this.loadChangeColumn = false
        this.testDatasetInfo.waitTable = false
      }
    },
    async reversePositive(reversePositive) {
      const targetIndex = this.data.pageBody.selectedColumnIndex

      this.loadChangeColumn = true
      try {
        this.testDatasetInfo.waitTable = true
        await this.getResultDetail({ targetIndex, reversePositive })
      } finally {
        this.loadChangeColumn = false
        this.testDatasetInfo.waitTable = false
      }
    },
    modelInfo: {
      handler(newVal) {
        if (newVal?.textMining != null) {
          this.setTextMiningParams()
        } else if (
          this.checkTimeTransformerV2 &&
          this.data.pageBody.timeTransformerV2Setting.trainingSetting &&
          this.data.pageBody.timeTransformerV2Setting.trainingSetting.length ===
            0 &&
          newVal?.train_config?.columnConditions != null
        ) {
          this.data.pageBody.timeTransformerV2Setting.trainingSetting =
            JSON.parse(JSON.stringify(newVal.train_config.columnConditions))
        }
        this.getResultDetailByChangeTrainedAi()
      },
      deep: true
    }
  }
}
</script>
