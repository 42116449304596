<template>
  <div class="c-wrap">
    <sidebar-train
      v-bind="sidebar"
      :incorrectOrder="incorrectOrder"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      @not-prevent="$emit('not-prevent')"
    />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page @after-enter="$emit('after-enter-page')">
        <transition-toggle-contents>
          <loading v-if="loading" />
          <training-main
            v-else
            v-bind="pageBody"
            :rawPredictionColumnOptions="rawPredictionColumnOptions"
            :selectedTrainingData="selectedTrainingData"
            :selectedTrainingDataSample="selectedTrainingDataSample"
            :selectedRecipe="selectedRecipe"
            :recipeType="recipeType"
            :accountInfo="accountInfo"
            :charts="charts"
            :chartsData="chartsData"
            :trainingProgressStatus="trainingProgressStatus"
            :elapsedTime="elapsedTime"
            :trainingNumIter="trainingNumIter"
            :trainingProgress="trainingProgress"
            :optimizationNumIter="optimizationNumIter"
            :optimizationProgress="optimizationProgress"
            :sortedTrainedAis="sortedTrainedAis"
            :mainData="mainData"
            :message="message"
            :errorManual="errorManual"
            :errorDetail="errorDetail"
            :sortedFlag="sortedFlag"
            :isSaved="isSaved"
            :isStopped="isStopped"
            :finishColumns="finishColumns"
            :loadChangeColumn="loadChangeColumn"
            :loadingReGet="loadingReGet"
            :bestParams="bestParams"
            :trials="trials"
            :resetKey="validateInfo.resetKey"
            :checkOptimization="checkOptimization"
            :learningPredictionColumns="learningPredictionColumns"
            :threshold="threshold"
            :reversePositive="reversePositive"
            :testDatasetInfo="testDatasetInfo"
            :showTrainedAiType="showTrainedAiType"
            :showTrainedAiIndex="showTrainedAiIndex"
            @delete-trained-ai="$emit('delete-trained-ai', $event)"
            @input-create-form="$emit('input-create-form', $event)"
            @save-trained-ai="$emit('save-trained-ai')"
            @show-graph="$emit('show-graph', $event)"
            @training-start="$emit('training-start', $event)"
            @training-stop="$emit('training-stop')"
            @background-training-stop="$emit('background-training-stop')"
            @page-back="$emit('page-back')"
            @change-column="$emit('change-column', $event)"
            @check-expressions="$emit('check-expressions', $event)"
            @save-edit-optimization-form="
              $emit('save-edit-optimization-form', $event)
            "
            @change-tab-optimization="$emit('change-tab-optimization')"
            @change-page="$emit('change-page', $event)"
            @change-test-dataset-page="
              $emit('change-test-dataset-page', $event)
            "
            @change-filter-value="$emit('change-filter-value', $event)"
            @show-detail-optimization="
              $emit('show-detail-optimization', $event)
            "
            @download-result="$emit('download-result', $event)"
            @download-test-dataset="$emit('download-test-dataset', $event)"
            @show-optimization-tutorial="$emit('show-optimization-tutorial')"
            @close-optimization-result-tutorial="
              $emit('close-optimization-result-tutorial')
            "
            @change-threshold="$emit('change-threshold', $event)"
            @reverse-positive="$emit('reverse-positive', $event)"
            @load-text-mining="$emit('load-text-mining', $event)"
            @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
            @update-show-type="$emit('update-show-type', $event)"
            @update-show-trained-ai="$emit('update-show-trained-ai', $event)"
            @show-time-transformer-before-setting="
              $emit('show-time-transformer-before-setting')
            "
            @input-clustering-setting="
              $emit('input-clustering-setting', $event)
            "
            @download-clustering-result="
              $emit('download-clustering-result', $event)
            "
            @change-clustering-distribution-column="
              $emit('change-clustering-distribution-column', $event)
            "
            @toggle-clustering-show-dimension="
              $emit('toggle-clustering-show-dimension', $event)
            "
            @select-regression-graph="$emit('select-regression-graph', $event)"
            @download-confusion-matrix="
              $emit('download-confusion-matrix', $event)
            "
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <training-popup
      v-bind="popup"
      :sortedTrainedAis="sortedTrainedAis"
      :accountInfo="accountInfo"
      :numOwntrainedAIs="numOwntrainedAIs"
      :numCreatingtrainedAIs="numCreatingtrainedAIs"
      :recipeType="recipeType"
      :loadingSave="loadingSave"
      :loadingDelete="loadingDelete"
      :disableClick="disableClick"
      :chartsData="chartsData"
      :runningJobs="runningJobs"
      :checkOptimization="checkOptimization"
      :errorDetail="errorDetail"
      :RAGresponse="RAGresponse"
      :datasetListBody="datasetListBody"
      :addDataset="addDataset"
      @close-modal="$emit('close-modal', $event)"
      @delete-confirm="$emit('delete-confirm')"
      @input-create-form="$emit('input-create-form', $event)"
      @move-inference="$emit('move-inference')"
      @re-train="$emit('re-train')"
      @save-confirm="$emit('save-confirm', $event)"
      @discard-confirm="$emit('discard-confirm')"
      @save-this-trained-ai="$emit('save-this-trained-ai')"
      @training-stop-confirm="$emit('training-stop-confirm')"
      @background-training-stop-confirm="
        $emit('background-training-stop-confirm', $event)
      "
      @training-start-over="$emit('training-start-over')"
      @move-this-project="$emit('move-this-project')"
      @move-optimization="$emit('move-optimization')"
      @select-time-transformer-setting="
        $emit('select-time-transformer-setting', $event)
      "
      @test-connection="$emit('test-connection', $event)"
      @test-prompt="$emit('test-prompt', $event)"
      @rag-submit="$emit('rag-submit', $event)"
      @add-new-data="$emit('add-new-data', $event)"
      @sort-label="$emit('sort-label', $event)"
      @show-dataset="$emit('show-dataset', $event)"
      @icon-menu-click="$emit('icon-menu-click', $event)"
      @load-dataset-list="$emit('load-dataset-list', $event)"
      @on-file-input="$emit('on-file-input', $event)"
      @start-uploading="$emit('start-uploading', $event)"
    />
    <optimization-tutorial
      v-if="checkOptimization"
      :showTutorial="popup.showTutorial.optimization"
      @close-tutorial="$emit('close-optimization-tutorial')"
    />
  </div>
</template>

<script>
import sidebarTrain from '@/components/organisms/sidebar-train.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import trainingMain from '@/components/organisms/training/training-main.vue'
import trainingPopup from '@/components/organisms/training/training-popup'
import optimizationTutorial from '@/components/organisms/optimization/optimization-tutorial'
import transitionPage from '@/components/molecules/transition-page'
import loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'

export default {
  components: {
    sidebarTrain,
    hoverMenu,
    headerTabs,
    trainingMain,
    trainingPopup,
    optimizationTutorial,
    transitionPage,
    loading,
    loadingProcessing,
    TransitionToggleContents
  },
  props: {
    sidebar: Object,
    headerTabs: Object,
    projectInfo: Object,
    progressTraining: Object,
    popup: Object,
    rawPredictionColumnOptions: Object,
    selectedTrainingData: Object,
    selectedTrainingDataSample: Object,
    selectedRecipe: Object,
    recipeType: String,
    accountInfo: Object,
    numOwntrainedAIs: Number,
    numCreatingtrainedAIs: Number,
    charts: Object,
    chartsData: Object,
    trainingProgressStatus: String,
    runningJobs: Array,
    elapsedTime: Number,
    trainingNumIter: Number,
    trainingProgress: Number,
    optimizationNumIter: Number,
    optimizationProgress: Number,
    sortedTrainedAis: Array,
    mainData: Object,
    loading: Boolean,
    loadingSave: Boolean,
    loadingDelete: Boolean,
    loadingReGet: Boolean,
    message: String,
    errorManual: String,
    errorDetail: Object,
    sortedFlag: Boolean,
    isSaved: Boolean,
    isStopped: Boolean,
    bestParams: Object,
    trials: Array,
    validateInfo: Object,
    disableClick: Boolean,
    pageBody: Object,
    incorrectOrder: Boolean,
    finishColumns: Array,
    loadChangeColumn: Boolean,
    checkOptimization: Boolean,
    showTutorial: Boolean,
    learningPredictionColumns: Array,
    threshold: Number,
    reversePositive: Boolean,
    testDatasetInfo: Object,
    showTrainedAiType: String,
    showTrainedAiIndex: Number,
    datasetListBody: Object,
    addDataset: Object,
    RAGresponse: Object
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background-color: $bg-train;
}
</style>
